import App from 'antd/lib/app';
import { add, startOfDay, startOfHour } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import Layout from 'antd/lib/layout';
import Empty from 'antd/lib/empty';
import Sider from 'antd/lib/layout/Sider';
import { PageHeader } from 'src/components/pageHeader/pageHeader';
import { Market } from 'src/domain/market-prices/interface';
import { usePeakLoadForecastContext } from './PeakLoadForecastContext';
import { PeakLoadMarketAndZoneSelector } from './PeakLoadMarketAndZoneSelector';
import { PeakLoadForecastDateSelector } from './PeakLoadForecastDateSelector';
import { PeakLoadForecastLocationSelector } from './PeakLoadForecastLocationSelector';
import { usePeakLoadPageState } from './usePeakLoadPageState';
import { PeakEventIndexWeekView } from './PeakEventIndexWeekView/PeakEventIndexWeekView';
import { useState } from 'react';
import { usePeakEventIndexQuery } from 'src/domain/peak-load-forecast/queries';
import { PeakLoadForecastCalendar } from './PeakLoadForecastCalendar/PeakLoadForecastCalendar';
import { PeakDayState, TopPeakDays } from './TopPeakDays/TopPeakDays';
import { PeakLoadForecastCustomizeView } from './PeakLoadForecastCustomizeView/PeakLoadForecastCustomizeView';
import { Content } from 'antd/lib/layout/layout';
import { SystemLoadChart } from './Charts/SystemLoadChart';
import { SolarIndexChart } from './Charts/SolarIndexChart';
import { RealFeelChart } from './Charts/RealFeelChart';
import { LmpChart } from './Charts/LmpChart';
import { PeakHoursChart } from './Charts/PeakHoursChart';
import { PeakLoadChart } from './Charts/PeakLoadChart';
import { IWeatherLocation } from 'src/domain/weather/interface';
import { PeakLoadForecastMarket } from './PeakLoadForecastMarket';
import { PeakEventIndexDay, preparePeakEventIndexDays } from './PeakEventIndexDay';
import { DateRange } from './DateRange';
import { InfoIcon } from './InfoIcon';
import { makeChartSettings } from './Charts/chart';
import { IPeakEventIndex } from 'src/domain/peak-load-forecast/interface';
import { isSameDayInTimezone } from 'src/domain/date/date';
import './PeakLoadForecast.css';

const today = new Date();

export function PeakLoadForecast() {
    const { notification } = App.useApp();

    const { markets, locations, defaultMarket, defaultLoadZone, defaultLocation, customizeViewSettings } = usePeakLoadForecastContext();
    console.info(`PeakLoadForecastView defaultMarket=${defaultMarket}, defaultLoadZone=${defaultLoadZone}`);
    const defaultLocationId = `${defaultLocation.latitude}:${defaultLocation.longitude}`;

    const { setPageState, pageState } = usePeakLoadPageState({
        market: defaultMarket,
        loadZone: defaultLoadZone,
        location: defaultLocationId,
        start: today.valueOf(),
        selected: today.valueOf(),
    });

    const plfMarket = new PeakLoadForecastMarket(pageState.market, pageState.loadZone);

    const dateRange = new DateRange(new Date(pageState.start), pageState.selected ? new Date(pageState.selected) : undefined);
    console.info(
        `[dateRange] start=${dateRange.start.toISOString()}, end=${dateRange.end.toISOString()}, selected=${dateRange.selected.toISOString()}`
    );

    const location: IWeatherLocation = findLocation(pageState.location, locations);

    const [selectedTopPeakDays, setSelectedTopPeakDays] = useState<PeakDayState[]>([]);

    function onMarketAndZoneChange(marketAndZone: string[]) {
        const [market, loadZone] = marketAndZone;
        setPageState({ ...pageState, market: market as Market, loadZone });
    }

    function onLocationChange(location: string) {
        setPageState({ ...pageState, location });
    }

    function onDateRangeChange(params: { start: number; end: number; newSelectedDay?: Date }) {
        const { start, newSelectedDay } = params;

        console.info(`[onDateRangeChange], start=${start}, newSelectedDay=${newSelectedDay?.toISOString()}`);
        setPageState({
            ...pageState,
            start,
            ...(newSelectedDay ? { selected: startOfHour(newSelectedDay).valueOf() } : {}),
        });
    }

    function onDaySelected(day: PeakEventIndexDay) {
        /**
         * !here we receive datetime in UTC but we need to convert it to the start of day in the timezone
         */
        const tDate = day.datetime.toISOString();
        const tDateFormatted = formatInTimeZone(tDate, plfMarket.timezone, 'yyyy-MM-dd');
        const tDateInTz = fromZonedTime(tDateFormatted, plfMarket.timezone);

        console.info('[onDaySelected] day', day);
        console.info('[onDaySelected] datetime', day.datetime.toISOString(), tDateInTz.toISOString());

        setPageState({
            ...pageState,
            selected: tDateInTz.valueOf(),
        });
    }

    function onCalendarDateSelected(date: Date) {
        const datetimeInTz = date;

        console.info(`[onCalendarDateSelected] date=${date.toISOString()}, datetimeInTz=${datetimeInTz.toISOString()} (${plfMarket.timezone})`);
        const selectedDate = datetimeInTz;

        const isWithinExistRange = selectedDate.valueOf() >= dateRange.start.valueOf() && selectedDate.valueOf() <= dateRange.end.valueOf();
        if (isWithinExistRange) {
            console.info(
                `[onCalendarDateSelected] selected calendar date ${selectedDate.toISOString()} is inside of the date range - just change selected day`
            );

            setPageState({
                ...pageState,
                selected: date.valueOf(),
            });

            return;
        }

        console.info(
            `[onCalendarDateSelected] selected calendar date ${selectedDate.toISOString()} is outside of the date range - load date for new date range`
        );
        const start = selectedDate.valueOf();
        setPageState({
            ...pageState,
            start,
            selected: selectedDate.valueOf(),
        });
    }

    function onTopPeakDaysChange(val: PeakDayState[]) {
        setSelectedTopPeakDays(val);
    }

    const peakEventIndexRes = usePeakEventIndexQuery(
        {
            start: fromZonedTime(startOfDay(dateRange.start), plfMarket.timezone),
            end: fromZonedTime(startOfDay(add(dateRange.end, { days: 1 })), plfMarket.timezone),
            market: plfMarket.name,
            loadZone: plfMarket.loadZone,
        },
        {
            keepPreviousData: true,
            enabled: !!(plfMarket.name && dateRange.start && dateRange.end),
        }
    );

    if (peakEventIndexRes.isError) {
        notification.error({
            key: 'plf-peak-event-index-error',
            message: peakEventIndexRes.error.message || 'Cannot load peak event index!',
        });
    }

    const peakEventIndexDays = preparePeakEventIndexDays(dateRange, peakEventIndexRes.data, plfMarket);

    let pageHeaderActions: React.ReactNode = [];
    if (plfMarket.isSupported) {
        pageHeaderActions = [
            <PeakLoadForecastDateSelector
                key="plf-date-selector"
                start={dateRange.start}
                end={dateRange.end}
                timezone={plfMarket.timezone}
                onChange={onDateRangeChange}
            />,
        ];
    }

    const dayEventIndex = findDayWithTimezone(peakEventIndexRes.data, dateRange.selected, plfMarket.timezone);

    const chartSettings = makeChartSettings(
        location,
        customizeViewSettings.timeframe,
        customizeViewSettings.layers.sunriseSunset.visible,
        plfMarket.timezone,
        dayEventIndex
    );

    return (
        <div id="peak-load-forecast">
            <div style={{ margin: '10px 0', display: 'flex' }}>
                <PeakLoadMarketAndZoneSelector
                    markets={markets}
                    value={[pageState.market, pageState.loadZone]}
                    onChange={onMarketAndZoneChange}
                    className="market-zone-selector"
                    key="plf-market-zone-selector"
                />
                {plfMarket.isSupported && (
                    <div key="plf-location">
                        <PeakLoadForecastLocationSelector
                            locations={locations}
                            value={pageState.location}
                            onChange={onLocationChange}
                            className="locations-selector"
                        />
                        <InfoIcon tooltip="Select the location to be used for weather data" />
                    </div>
                )}
            </div>
            <PageHeader
                pageTitle={
                    <div>
                        Peak Load Index
                        <InfoIcon tooltip="Peak Load Index compares the daily forecast to the average peak load for the month from the last 3 years. A value of 100 means the forecast is the same MW value as that average." />
                    </div>
                }
                extra={[]}
                actions={pageHeaderActions}
            />
            {plfMarket.isSupported === false ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sorry, selected market is not supported." />
            ) : (
                <>
                    <PeakEventIndexWeekView days={peakEventIndexDays} market={plfMarket} onChange={onDaySelected} />
                    <Layout style={{ minHeight: '300px' }}>
                        <Sider className="sidebar" width="260px">
                            <div style={{ width: '100%', padding: '30px 10px 10px 10px' }}>
                                <PeakLoadForecastCalendar date={dateRange.selected} market={plfMarket} onChange={onCalendarDateSelected} />
                            </div>
                            <div style={{ width: '100%', padding: '10px 10px 20px 10px' }}>
                                <TopPeakDays
                                    selectedDay={dateRange.selected}
                                    selectedState={selectedTopPeakDays.map(it => it.selected) as [boolean, boolean, boolean]}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    onChange={onTopPeakDaysChange}
                                />
                            </div>
                            <div style={{ width: '100%', padding: '10px', borderTop: '1px solid #D6DBDB' }}>
                                <PeakLoadForecastCustomizeView />
                            </div>
                        </Sider>
                        <Content style={{ backgroundColor: '#f7f8f8', padding: '20px' }}>
                            {customizeViewSettings.order.peakLoad.visible && (
                                <PeakLoadChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                    topPeakDays={selectedTopPeakDays}
                                />
                            )}
                            {customizeViewSettings.order.peakHours.visible && (
                                <PeakHoursChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                />
                            )}
                            {customizeViewSettings.order.realFeel.visible && (
                                <RealFeelChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                    location={location}
                                />
                            )}
                            {customizeViewSettings.order.lmp.visible && (
                                <LmpChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                />
                            )}
                            {customizeViewSettings.order.solarIndex.visible && (
                                <SolarIndexChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone={plfMarket.loadZone}
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                    location={location}
                                />
                            )}
                            {customizeViewSettings.order.systemLoad.visible && (
                                <SystemLoadChart
                                    date={dateRange.selected}
                                    market={plfMarket.name}
                                    loadZone="AGGREGATED"
                                    timezone={plfMarket.timezone}
                                    settings={chartSettings}
                                />
                            )}
                        </Content>
                    </Layout>
                </>
            )}
        </div>
    );
}

function findDayWithTimezone(peakEventIndexDays: IPeakEventIndex[] | undefined, day: Date, timezone: string): IPeakEventIndex[] {
    if (!peakEventIndexDays) return [];

    return peakEventIndexDays.filter(item => {
        return isSameDayInTimezone(day, new Date(item.datetime), timezone);
    });
}

function findLocation(locId: string, locations: IWeatherLocation[]) {
    return locations.find(loc => `${loc.latitude}:${loc.longitude}` === locId) || locations[0];
}
