import { getColumnFilteredValue } from '../../components/table/columnFormatHelpers';
import { ColumnsType } from 'antd/lib/table';
import { ISite } from './interface';
import { SITE_STATUS_FILTER_VALUES, SITE_STATUS_TEXT_MAP } from '../../pages/sites/siteInterface';
import flatten from 'lodash/flatten';

export const getStatusFilterValue = (state: any) => {
    let { filteredValue } = getColumnFilteredValue('status', state?.filter || {});
    if (Array.isArray(filteredValue)) {
        filteredValue = flatten(filteredValue);
    }
    return filteredValue;
};

export function buildSiteStatusTableFilter(state: any, sidebarEnabled: boolean) {
    const column: ColumnsType<ISite>[number] = {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => SITE_STATUS_TEXT_MAP[text],
        width: '100px',
    };

    return sidebarEnabled ? [column] : [{ ...column, filters: SITE_STATUS_FILTER_VALUES, filteredValue: getStatusFilterValue(state) }];
}
