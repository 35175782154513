import SunCalc from 'suncalc';
import { IWeatherLocation } from 'src/domain/weather/interface';

export function calcSunriseSunsetByLocation(location: IWeatherLocation, datetime?: string) {
    const { latitude, longitude } = location;
    const date: Date = datetime ? new Date(datetime) : new Date();
    const times = SunCalc.getTimes(date, Number(latitude), Number(longitude));

    return { sunrise: times.sunrise, sunset: times.sunset };
}
