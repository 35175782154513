import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { FC, useEffect, useState } from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import { IFilterOption, useSidebar } from '../context/SidebarContext';
import Checkbox from 'antd/lib/checkbox';
import './sidebarModal.css';
import { API } from 'src/domain/api';
import { SiteSavedFilterDto } from '../SidebarSavedFilter/SidebarSavedFilter';
import App from 'antd/lib/app';
import { useAuth } from 'src/domain/auth/useAuth';
import { WarningOutlined } from '@ant-design/icons/lib/icons';
import { SidebarActiveFilters } from '../SidebarActiveFilters/SidebarActiveFilters';

interface SidebarModalProps {
    open: boolean;
    handleCancel: () => void;
    handlerConfirm: () => void;
    selection: Record<string, string | string[]> | undefined;
    subType: string;
    item?: SiteSavedFilterDto;
    increaseTriggerFetchAll: () => void;
}

interface ISidebarForm {
    filter_name: string;
    description: string;
    display_in_dashboard: boolean;
}

interface OptionBody {
    control_provider_id?: number | string;
    exist_salesforce_meter_enrollments?: boolean;
    exist_salesforce_meters?: boolean;
    interval_data?: string;
    site_is_closed?: boolean;
    lmp_market?: string;
    utility_customer_id?: number;
    program_id?: string;
    site_utility?: string;
    status?: string;
}

const SidebarModal: FC<SidebarModalProps> = ({ open, handleCancel, handlerConfirm, selection, item, subType, increaseTriggerFetchAll }) => {
    const { notification } = App.useApp();
    const { savedFilters, getSelectedFiltersToDisplay, getSelectedFilterFromItemToDisplay, setSelectedFilterId } = useSidebar();
    const [selectedFilters, setSelectedFilters] = useState<IFilterOption[]>([]);
    const [title, setTitle] = useState('Save Filter Selections');
    const [submittable, setSubmittable] = useState<boolean>(false);
    const [selectionFromItem, setSelectionFromItem] = useState<Record<string, string | string[]>>();
    const [form] = Form.useForm<ISidebarForm>();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdatingFilter, setIsUpdatingFilter] = useState(false);
    const auth = useAuth();

    // Watch all values
    const values = Form.useWatch([], form);

    useEffect(() => {
        if (item?.id) {
            form.setFieldValue('filter_name', item.filter_name);
            form.setFieldValue('description', item.description);
            form.setFieldValue('display_in_dashboard', item.display_in_dashboard);
            setTitle(`Edit "${item.filter_name}"`);
            let select: Record<string, string | string[]> = {};
            const filters = getSelectedFilterFromItemToDisplay(item);
            filters.forEach(f => {
                select[f.key] = f.value;
            });
            setSelectionFromItem(select);
            setSelectedFilters(filters);
            return;
        }

        if (selection) {
            setSelectedFilters(getSelectedFiltersToDisplay(selection));
        }
    }, [selection, setSelectedFilters, item]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);

    const filterName = Form.useWatch('filter_name', form);

    useEffect(() => {
        const isFilterOwner = savedFilters.find(s => s.user_id === auth?.user?.user_id && s.filter_name === filterName) !== undefined;
        setIsUpdatingFilter(item?.id !== undefined ? false : isFilterOwner);
    }, [filterName]);

    const handleSubmit = async (values: ISidebarForm) => {
        if (selectedFilters.length === 0) {
            notification.warning({
                message: 'Filter',
                description: `You must have at least one filter selected.`,
            });
            return;
        }

        setIsLoading(true);
        let options: OptionBody = {};
        selectedFilters.forEach(filter => {
            switch (filter.key) {
                case 'control_provider_id':
                    options.control_provider_id = filter.value.includes(',') ? filter.value : Number(filter.value);
                    break;
                case 'utility_customer_id':
                    options.utility_customer_id = Number(filter.value);
                    break;
                case 'exist_salesforce_meter_enrollments':
                    options.exist_salesforce_meter_enrollments = filter.value === 'true';
                    break;
                case 'exist_salesforce_meters':
                    options.exist_salesforce_meters = filter.value === 'true';
                    break;
                case 'interval_data':
                    options.interval_data = filter.value;
                    break;
                case 'lmp_market':
                    options.lmp_market = filter.value;
                    break;
                case 'program_id':
                    options.program_id = filter.value;
                    break;
                case 'site_is_closed':
                    options.site_is_closed = Boolean(filter.value);
                    break;
                case 'site_utility':
                    options.site_utility = filter.value;
                    break;
                case 'status':
                    options.status = filter.value;
                    break;
            }
        });

        const newFilter = {
            filter_values: options,
            description: values.description,
            status: 'active',
            type: 'settings',
            filter_name: values.filter_name,
            sub_type: subType,
            display_in_dashboard: values.display_in_dashboard,
        };
        if (!item?.id) {
            // TODO create hook for saving filters
            const response = await API.fetch('/filters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFilter),
            });

            if (response.status === 201) {
                const responseBody = await response.json();
                notification.success({
                    message: 'Filter Saved',
                    description: `"${newFilter.filter_name}" ${isUpdatingFilter ? 'filter updated' : 'filter saved. \n Find it in your Saved tab'} `,
                });
                form.resetFields();
                setIsLoading(false);
                setIsUpdatingFilter(false);
                handlerConfirm();
                increaseTriggerFetchAll();
                setSelectedFilterId(responseBody.id); // Set the newly saved filter ID
            } else {
                notification.error({
                    message: 'Could not save filter',
                    description: `Please try again in some minutes.`,
                });
                setIsLoading(false);
            }
        } else {
            // TODO create hook for updating filters
            const response = await API.fetch(`/filters/${item.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFilter),
            });

            if (response.status === 200) {
                const responseBody = await response.json();
                notification.success({
                    message: 'Filter Saved',
                    description: `"${newFilter.filter_name}" filter updated'`,
                });
                form.resetFields();
                setIsLoading(false);
                setIsUpdatingFilter(false);
                handlerConfirm();
                increaseTriggerFetchAll();
                setSelectedFilterId(responseBody.id); // Set the newly updated filter ID
            } else {
                notification.error({
                    message: 'Could not save filter',
                    description: `Please try again in some minutes.`,
                });
                setIsLoading(false);
            }
        }
    };

    const handleCancelModal = () => {
        setTitle('Save Filter Selections');
        form.resetFields();
        setIsUpdatingFilter(false);
        setSelectionFromItem(undefined);
        handleCancel();
    };

    const handleRemoveActiveFilter = (filter: IFilterOption, event: React.MouseEvent<HTMLElement>) => {
        if (selectedFilters.length === 1) {
            event.preventDefault();
            notification.warning({
                message: 'Filter',
                description: `You must have at least one filter selected.`,
            });
            return;
        }

        const foundFilter = selectedFilters.find(f => f.key === filter.key);
        if (foundFilter) {
            setSelectedFilters(selectedFilters.filter(f => f.key !== filter.key));
        }
    };

    return (
        <Modal
            open={open}
            title={title}
            onOk={handlerConfirm}
            onCancel={handleCancelModal}
            footer={[
                <>
                    <Button key="back" onClick={handleCancelModal}>
                        Cancel
                    </Button>
                    {isUpdatingFilter && (
                        <Typography.Link className="update-existing-filter-btn" onClick={form.submit}>
                            Update Existing Filter
                        </Typography.Link>
                    )}
                    <Button
                        key={'save'}
                        type="primary"
                        onClick={form.submit}
                        disabled={!submittable || isUpdatingFilter || selectedFilters.length === 0}
                        loading={isLoading}
                    >
                        Save
                    </Button>
                </>,
            ]}
        >
            <Form className="sidebarForm" form={form} layout="vertical" onFinish={handleSubmit} preserve={false}>
                {isUpdatingFilter && (
                    <div className="update-existing-filter-container">
                        <WarningOutlined className="update-existing-filter-icon" />
                        <Typography.Paragraph className="update-existing-filter-message">
                            A filter with the name "{filterName}" already exists. Use a different name to save a new filter.
                        </Typography.Paragraph>
                    </div>
                )}

                <Form.Item
                    name="filter_name"
                    label={<Typography.Text strong>Name</Typography.Text>}
                    rules={[
                        { required: true, message: 'Please enter name!' },
                        { max: 100, message: 'Number of characters should be less than 100' },
                    ]}
                >
                    <Input status={isUpdatingFilter ? 'warning' : ''} placeholder="Name of filter" size="large" />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={<Typography.Text strong>Description</Typography.Text>}
                    rules={[{ max: 255, message: 'Number of characters should be less than 255' }]}
                >
                    <Input.TextArea placeholder="Description" size="large" rows={2} />
                </Form.Item>

                <div>
                    <Typography.Text>Filters</Typography.Text>
                    <SidebarActiveFilters filters={selectionFromItem ?? selection} handleClose={handleRemoveActiveFilter} />
                </div>
                <div>
                    <Typography.Text>Options</Typography.Text>
                    <Form.Item name="display_in_dashboard" valuePropName="checked">
                        <Checkbox className="checkbox">Show on Dashboard</Checkbox>
                    </Form.Item>
                    <Typography.Text className="description">Display this filter and new results on DRMS dashboard</Typography.Text>
                    {/* Commented for feature releases
                    <div className="checkSection">
                        <Checkbox className="checkbox">Notifications</Checkbox>
                        <Typography.Text className="description">
                            Receive email notifications anytime a new result matches your filter settings
                        </Typography.Text>
                    </div>
                    */}
                </div>
            </Form>
        </Modal>
    );
};

export default SidebarModal;
