import { FC } from 'react';
import { EChartsOption } from 'echarts';
import { isArray, mean, round } from 'lodash';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { add, endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { Market } from 'src/domain/market-prices/interface';
import { usePeakDaysQuery } from 'src/domain/peak-load-forecast/queries';
import { IPeakDays, IPeakLoad } from 'src/domain/peak-load-forecast/interface';
import {
    calculateTooltipPosition,
    CHART_DATA_COLOR,
    ChartSettings,
    fillSeriesData,
    renderChartTooltip,
    makeChartAreasSeries,
    parseDateTimeComponents,
    peakLoadToSeriesData,
    SeriesItem,
    applyTimeframe,
    getShortTimezone,
} from './chart';
import { PlfChart } from './PlfChart';
import { InfoIcon } from '../InfoIcon';
import { TopLevelFormatterParams } from 'echarts/types/dist/shared';
import './Chart.css';

type PeakHoursChartProps = {
    date: Date;
    market: Market;
    loadZone: string;
    timezone: string;
    settings: ChartSettings;
};

export const PeakHoursChart: FC<PeakHoursChartProps> = ({ date, market, loadZone, timezone, settings }) => {
    const start = fromZonedTime(startOfDay(date), timezone);
    const end = fromZonedTime(endOfDay(date), timezone);
    const datetime = fromZonedTime(startOfMonth(date), timezone);

    const { isFetching, data } = usePeakDaysQuery(
        { datetime, market, loadZone },
        {
            keepPreviousData: true,
            staleTime: 1 * 60 * 1000, // 1h
        }
    );

    const areaSeries = makeChartAreasSeries(settings);

    /**
     * ! here to make all charts looks the same - we remove 24 hours (the last hour) from series
     * ! this might be changed in the future
     */
    // @ts-ignore
    areaSeries[0].xAxisIndex = 1;
    // @ts-ignore
    areaSeries[0].markArea.data.pop();

    const peakHours = calculatePeakHours(start, data);
    const dataSeries = prepareSeries(peakHours, start, end, settings.timeframe);

    // @ts-ignore
    dataSeries[0].data.pop();

    const series: EChartsOption['series'] = [...areaSeries, ...dataSeries];

    const areaSeriesData = applyTimeframe([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23], settings.timeframe);

    const option: EChartsOption = {
        tooltip: {
            trigger: 'axis',
            padding: 0,
            borderColor: '#ffffff',
            axisPointer: {
                type: 'line',
                lineStyle: {
                    type: 'solid',
                },
            },
            position: (point, params, dom, rect, size) => calculateTooltipPosition(point, size.contentSize),
            formatter: params => formatPeakHoursTooltip(params, timezone),
        },
        xAxis: [
            {
                z: 1,
                type: 'category',
                boundaryGap: true,
                axisLabel: {
                    color: '#697475',
                    margin: 12,
                    formatter: (datetime: unknown) => {
                        const hours = parseInt(formatInTimeZone(new Date(datetime as string), timezone, 'H'));
                        const hourEnding = hours + 1;
                        return hourEnding.toString();
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#d6dbdb',
                        width: 2,
                        type: 'solid',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                },
            },
            {
                z: 2,
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#d6dbdb',
                        width: 0,
                        type: 'solid',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                data: areaSeriesData,
            },
        ],
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: (value: number) => {
                    return `${new Intl.NumberFormat('en-EN').format(value)} MW`;
                },
            },
        },
        series: series,
    };

    return (
        <div className="peak-load-forecast-base-chart">
            <div className="peak-load-forecast-chart-title">
                Peak Hours
                <InfoIcon tooltip="Average hourly load for the monthly peak day for the past 3 years" />
            </div>
            <PlfChart style={{ height: '320px', width: '100%' }} showLoading={isFetching} timezone={timezone} option={option} />
            <div className="chart-timezone">{getShortTimezone(date, timezone)}</div>
        </div>
    );
};

function calculatePeakHours(datetime: Date, peakDays: IPeakDays[] | undefined) {
    const peakHours: IPeakLoad[] = [];

    if (!peakDays?.length) return peakHours;

    const { market, loadZone } = peakDays[0].data[0];

    const oneDayHoursMap: Record<string, number> = {};
    for (let hours = 0; hours < 24; hours++) {
        const key = add(datetime, { hours }).toISOString();
        oneDayHoursMap[key] = 0;
    }

    const peakDaysDataMap: Record<string, number[]> = {};
    peakDays.forEach(({ data }) => {
        data.forEach(item => {
            const { hours } = parseDateTimeComponents(item.datetime);
            const key = `${hours}`;
            if (typeof peakDaysDataMap[key] === 'undefined') peakDaysDataMap[key] = [];
            peakDaysDataMap[key].push(item.loadMw);
        });
    });

    for (let key in oneDayHoursMap) {
        const { hours } = parseDateTimeComponents(key);
        const values = peakDaysDataMap[`${hours}`] || [];
        oneDayHoursMap[key] = round(mean(values));
    }

    for (let [datetime, loadMw] of Object.entries(oneDayHoursMap)) {
        peakHours.push({
            market,
            loadZone,
            datetime,
            loadMw,
        });
    }

    return peakHours;
}

function formatPeakHoursTooltip(params: TopLevelFormatterParams, timezone: string) {
    if (!isArray(params)) return '';

    const items = params.filter(item => item.seriesName !== 'Areas');
    if (items.length === 0) return '';
    if (!isArray(items[0].value)) return '';

    const [datetime] = items[0].value;
    const datetimeHourEnding = add(new Date(datetime), { hours: 1 });
    const hourInTimezone = formatInTimeZone(datetimeHourEnding, timezone, 'H');

    const series: SeriesItem[] = items.map(item => {
        return {
            name: `HE ${hourInTimezone}`,
            color: item.color as string,
            style: 'solid',
            value: item.value as [string, number],
            uom: ' MW',
        };
    });

    return renderChartTooltip(series, timezone);
}

function prepareSeries(data: IPeakLoad[] | undefined, start: Date, end: Date, timeframe: [number, number]) {
    const series: EChartsOption['series'] = [];

    if (!data) return series;

    const peakHoursData = peakLoadToSeriesData(data);
    const seriesData = fillSeriesData(peakHoursData, start, end, timeframe);

    series.push({
        name: 'Forecasted',
        type: 'bar',
        itemStyle: {
            color: CHART_DATA_COLOR,
        },
        barWidth: '60%',
        data: seriesData,
    });

    return series;
}
