import FullCalendar, { DatesSetArg, EventContentArg, VerboseFormattingArg } from '@fullcalendar/react';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card/Card';
import Popover from 'antd/lib/popover';
import Spin from 'antd/lib/spin';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import { addDays, startOfDay, parseISO } from 'date-fns';
import intersection from 'lodash/intersection';
import { createRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { MarketZoneSelector } from '../../../components/selectors/MarketZoneSelector/MarketZoneSelector';
import { ProgramSelector } from '../../../components/selectors/ProgramSelector/ProgramSelector';
import { FORM_MODE, useFormMode } from '../../../components/useFormMode';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { WithControlledTooltip } from '../../../components/withControlledTooltip';
import config from '../../../config';
import { formatToMinutesAndHours } from '../../../domain/common/dateFormatters';
import { minutesToHours } from '../../../domain/common/timeHelpers';
import { NO_LOAD_ZONE_TEXT, NO_MARKET_TEXT } from '../../../domain/event';
import { toCalendarEvents } from '../../../domain/event/calendarEvent';
import { IEvent, OptType, SignalType, SliceType } from '../../../domain/event/interface';
import { useEventListQuery, useResourceListQuery } from '../../../domain/event/queries';
import { IResource, Resource } from '../../../domain/event/resource';
import { IPaginationMeta } from '../../../domain/IPagination';
import { IProgram } from '../../../domain/program/interface';
import { STATE_ABBREVIATIONS } from '../../../domain/site/stateAbbreviations';
import { usePageLocation } from '../../usePageState';
import { EventByProgramFormModal } from '../components/EventByProgramFormModal';
import { EventBySiteFormModal } from '../components/EventBySiteFormModal';
import { EventLegendOverlay } from '../components/EventLegendOverlay';
import { eventViewOptions, VIEW_MODE } from '../eventInterface';
import { prepareCalendarDates, toUsefulEventParams } from '../utils';

import './EventCalendarView.css';
import { getUtcFormattedTime } from 'src/domain/date/date';
import { useAuth } from 'src/domain/auth/useAuth';

const initialEventValues = {
    event_id: undefined,
    event_cancelled: false,
    event_datetime_start: undefined,
    event_duration: 60,
    event_emergency_generator_allowed: false,
    event_voluntary: false,
    event_market_context: 'Default Program (enersponse)',
    event_modification_number: undefined,
    event_opt_type: OptType.UNSET,
    event_post_duration: 0,
    event_pre_duration: 0,
    event_secondary_duration: 0,
    event_signal: SignalType.HIGH,
    event_test: false,
    event_datetime_created: undefined,
    event_slice: {
        type: SliceType.NONE,
        amount: 0,
    },
};

enum EventCalendarEmitter {
    SITE = 'site',
    PROGRAM = 'program',
}

export const EventCalendarView = ({ company, view, handleViewMode }: IView) => {
    const auth = useAuth()!;

    const { notification } = App.useApp();
    const location = useLocation();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = useMemo(() => queryToState(location.search), [location, queryToState]);

    const ability = useContext(AbilityContext);

    const [resources, setResources] = useState<IResource[]>([]);
    const [event, setEvent] = useState<Partial<IEvent> | null>();
    const { onSetFormMode, isEditMode } = useFormMode();
    const isResourcesSelected = useMemo(() => resources.some(resource => resource.selected), [resources]);
    const resourcesMapRef = useRef(new Map<string, IResource | Partial<IResource>>());
    const [emitBy, setEmitBy] = useState<EventCalendarEmitter | null>(null);

    const calRef: any = createRef();
    const isNewEvent = !event?.event_id;

    const listQuery = {
        ...toUsefulEventParams({
            ...pageState,
            companyId: company,
            programId: pageState?.programId,
        }),
    };

    const isFilterByMarketZone = !!pageState?.market || !!pageState?.load_zone;
    const isFilterBySearch = !!pageState?.search;
    const isFilterByProgram = !!pageState?.programId;
    const isFilterByCompany = !!company;

    const isEnabled = isFilterByCompany || isFilterByMarketZone || isFilterBySearch || isFilterByProgram;

    const { data: resourcesData } = useResourceListQuery(
        {
            ...listQuery,
        },
        {
            enabled: isEnabled,
        }
    );

    const {
        data: eventsData,
        isFetching,
        refetch: refetchEvents,
    } = useEventListQuery(
        {
            ...listQuery,
            include: 'site,company,program',
        },
        {
            enabled: isEnabled,
        }
    );

    const events = eventsData?.data || [];
    const meta = eventsData?.meta || ({ total: 0, limit: 0 } as IPaginationMeta);

    if (view === VIEW_MODE.CALENDAR && meta.total > meta.limit) {
        notification.warning({ key: 'events-fetch-limit', message: `We display only ${meta.limit} events` });
    }

    const clearResources = (companyId: number | undefined | null, programId: number | undefined) => {
        if (!companyId && !programId) {
            setResources([]);
            resourcesMapRef.current.clear();
        }
    };

    useEffect(() => {
        const companyId = company;
        const programId = pageState?.program?.program_id;

        clearResources(companyId, programId);

        if (resourcesData) {
            const preparedResources = Resource.transformList(resourcesData);
            preparedResources.forEach(resource => resourcesMapRef.current.set(resource.id, resource));
            setResources(preparedResources);
        }
    }, [company, pageState?.program?.program_id, resourcesData]);

    useEffect(() => {
        if (event) {
            setEvent(null);
        }
    }, [company]);

    const handleSearch = (value: string) => {
        setPageQuery({ ...pageState, search: value });
    };

    const handleDatesSet = (dateInfo: DatesSetArg) => {
        const calendarView = dateInfo.view.type;

        const dates = prepareCalendarDates(calendarView, dateInfo.start, dateInfo.end);

        setPageQuery({
            ...pageState,
            calendarView,
            calendarDates: dates,
            ...(company && { companyId: company }),
        });
    };

    const resourceAreaColumns = [
        {
            field: 'company',
            group: true,
            headerContent: 'Company',
        },
        {
            field: '_market_load_zone',
            group: true,
            headerContent: 'Market, Load Zone',
        },
        {
            field: '_program',
            group: true,
            headerContent: 'Program',
        },
        {
            field: 'site',
            headerContent: (
                <span>
                    Site{' '}
                    <Tooltip title="Site, state (offset, max duration)">
                        <QuestionCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
                    </Tooltip>
                </span>
            ),
            cellClassNames: (props: any) => {
                const isHighlight = props.resource._resource.extendedProps.site_highlight;

                return isHighlight ? 'site-highlight' : '';
            },
        },
    ];

    function resourceGroupLabelClickHandler(res: Partial<IResource>) {
        if (!isNewEvent) {
            return;
        }

        const compare = (resource: Partial<IResource>, item: IResource): boolean => {
            const companyTheSame = !resource.company || resource.company === item.company;
            const marketTheSame = !resource.lmp_market || resource.lmp_market === item.lmp_market;
            const loadZoneTheSame = !resource.site_load_zone || resource.site_load_zone === item.site_load_zone;

            const intersectionPrograms = intersection(resource.programs, item.programs);
            const programsTheSame = !resource.programs || Boolean(intersectionPrograms.length);

            return companyTheSame && marketTheSame && programsTheSame && loadZoneTheSame;
        };

        const children: IResource[] = resources.filter(r => compare(res, r));

        const isAllSelected = children.every(item => item.selected);
        const isAllUnselected = children.every(item => !item.selected);

        let newSelectedState = true;
        if (isAllSelected) newSelectedState = false;
        if (isAllUnselected) newSelectedState = true;

        const items: IResource[] = resources.map(r => {
            if (compare(res, r)) r.selected = newSelectedState;
            return r;
        });

        setResources(items);
    }

    function resourceLabelClickHandler(res: Partial<IResource>) {
        if (!isNewEvent || !res?.id) return;

        const items: IResource[] = resources.map(r => {
            if (r.id === res.id) r.selected = !r.selected;
            return r;
        });
        setResources(items);
    }

    const resourceGroupLabelContentRenderer = (...props: any) => {
        const [item] = props;
        const value = item.groupValue ?? item.fieldValue;

        const res = Resource.parseValue(value);
        if (!res) return <></>;

        let withTooltip = false;
        let label: string = '';

        if (typeof res.programs !== 'undefined') {
            withTooltip = true;

            label = res.programs?.map(program => program).join('; ');
        } else if (typeof res.site_load_zone !== 'undefined') {
            withTooltip = true;

            const market = res.lmp_market ? res.lmp_market : '-';
            label = res.site_load_zone ? `${market.toUpperCase()}, ${res.site_load_zone}` : '';
        } else if (res.lmp_market && typeof res.lmp_market !== 'undefined') {
            withTooltip = true;

            let loadZone = res.site_load_zone ? `, ${res.site_load_zone}` : '';
            label = loadZone && !res.lmp_market ? `-${loadZone}` : `${res.lmp_market}${loadZone}`;
        } else if (typeof res.company !== 'undefined') {
            withTooltip = true;

            label = res.company;
        }

        label = hideEmptyMarketOrLoadZoneLabel(label);

        const content = (
            <CheckableTag
                checked={false}
                onClick={() => (ability.can('create', 'Event') ? resourceGroupLabelClickHandler(res) : null)}
                style={{ cursor: isNewEvent ? 'pointer' : 'not-allowed', maxWidth: '200px' }}
            >
                {label}
            </CheckableTag>
        );

        return withTooltip ? <Tooltip title={label}>{content}</Tooltip> : content;
    };

    const resourceLabelContentRenderer = (item: any) => {
        if (!resourcesMapRef.current.size) {
            return undefined;
        }

        const id = item?.resource?._resource?.id;
        const res = resourcesMapRef.current.get(id);
        if (!res) {
            console.error(`Resource with ID ${id} not found!`);
            return <></>;
        }

        const siteState = STATE_ABBREVIATIONS[res.site_state!] || res.site_state;
        const eventOffset = auth.user?.isAdminRoleType() && res.event_offset! > 0 ? `${res.event_offset}m` : '';
        const eventMaxDuration = res.event_max_duration ? `${minutesToHours(res.event_max_duration)}hr` : '';
        const durationInfo = eventOffset || eventMaxDuration ? ` (${eventOffset || '0m'}, ${eventMaxDuration || '0hr'})` : '';

        const marketLoadZone = getMarketLoadZoneLabel(res);

        const siteInfo = (
            <div>
                <div>{res.site}</div>
                <div>{res.site_address}</div>
                {res.utility && <div>{res.utility}</div>}
                {marketLoadZone && <div>{marketLoadZone}</div>}
                {res.sans && res.sans.length > 0 && <div>{res.sans.join(', ')}</div>}
            </div>
        );

        return (
            <Tooltip title={siteInfo}>
                <CheckableTag
                    checked={!!res?.selected}
                    onClick={() => (ability.can('create', 'Event') ? resourceLabelClickHandler(res) : null)}
                    style={{ cursor: isNewEvent ? 'pointer' : 'not-allowed' }}
                    data-cy="site"
                >
                    {item.fieldValue}, {siteState}
                    {durationInfo}
                </CheckableTag>
            </Tooltip>
        );
    };

    function showNewEventForm() {
        onSetFormMode(FORM_MODE.NEW);
        setEvent(initialEventValues);

        setEmitBy(EventCalendarEmitter.SITE);
    }

    const showEventByProgramForm = () => {
        onSetFormMode(FORM_MODE.NEW);
        setEvent(initialEventValues);

        setEmitBy(EventCalendarEmitter.PROGRAM);
    };

    const handleProgramChange = (program: IProgram) => {
        const programId = program?.program_id;

        setPageQuery({ ...pageState, programId });
    };

    const handleMarketLoadZone = (marketAndZone: string[]) => {
        let market = null;
        let load_zone = null;
        if (marketAndZone?.length) {
            market = marketAndZone[0];
            load_zone = marketAndZone[1];
        }

        setPageQuery({ ...pageState, market, load_zone });
    };

    const onEventClick = (info: any) => {
        if (info.view.type === 'dayGridMonth') return undefined;

        const eventResources = info.event.getResources();
        const eventResourceId = eventResources.map((resource: IResource) => resource.id)[0];
        const clickedEventId = info.event.id;
        const currentEvent = events.filter(event => event.event_id === Number(clickedEventId))[0];

        const resourcesItems: IResource[] = resources.map(r => {
            r.selected = false;
            if (r.id === eventResourceId) r.selected = !r.selected;
            return r;
        });

        onSetFormMode(FORM_MODE.EDIT);

        setEmitBy(EventCalendarEmitter.SITE);
        setResources(resourcesItems);
        setEvent(currentEvent);
    };

    const onEventFormClose = async (preparedEvent?: any) => {
        setEvent(null);

        if (!preparedEvent) return;

        const eventsQuery = toUsefulEventParams(pageState);
        const query = {
            ...eventsQuery,
            ...(eventsQuery.start ?? { start: startOfDay(new Date()) }),
            ...(eventsQuery.end ?? { end: startOfDay(addDays(new Date(), 1)) }),
            // programId: eventsQuery?.program_id,
            include: 'site,company,program',
        };

        await refetchEvents(query);

        setEmitBy(null);

        const deselectedResources = resources.map(r => {
            r.selected = false;
            return r;
        });

        setResources(deselectedResources);
    };

    const getSelectedResources = (resources: IResource[]): IResource[] => {
        return resources.filter(r => r.selected);
    };

    const transformedEventsToCalendarView = useMemo(() => toCalendarEvents(events, pageState?.calendarView), [events, pageState?.calendarView]);

    const eventContentHandler = (props: EventContentArg) => {
        if (props.view.type === 'dayGridMonth') {
            const content = (
                <>
                    {props.event.title && (
                        <Typography.Paragraph>
                            Programs: <Typography.Text strong>{props.event.title}</Typography.Text>
                        </Typography.Paragraph>
                    )}
                    <Typography.Text>
                        Events duration: <Typography.Text strong>{formatToMinutesAndHours(props.event.extendedProps.duration)}</Typography.Text>
                    </Typography.Text>
                </>
            );

            return (
                <Popover content={content}>
                    <div className="event-month-content">
                        <div className="fc-daygrid-event-dot" style={{ borderColor: 'rgb(238, 238, 238)' }}></div>
                        <div className="fc-event-time">{props.timeText}</div>
                        <div className="fc-event-title" style={{ textOverflow: 'ellipsis', fontWeight: 400 }}>
                            {props.event.title}
                        </div>
                    </div>
                </Popover>
            );
        } else if (props.event?.title?.length > 0) {
            return (
                <Popover content={<Typography.Text strong>{props.event.title}</Typography.Text>}>
                    <div className="fc-event-title" style={{ textOverflow: 'ellipsis' }}>
                        {props.event.title}
                    </div>
                </Popover>
            );
        }
    };

    const getCalendarTitle = useCallback((options: VerboseFormattingArg, type: string) => {
        const defaultDateFormat = 'dd MMM, yyyy';
        let formattedTitle = '';

        const _startUTCDate = parseISO(new Date(options?.start?.marker).toISOString());
        const _endUTCDate = parseISO(new Date(options?.end?.marker || '').toISOString());

        switch (type) {
            case 'month':
                {
                    const _startFormat = getUtcFormattedTime(_startUTCDate, defaultDateFormat);
                    const _endFormat = getUtcFormattedTime(_endUTCDate, defaultDateFormat);
                    formattedTitle = `${_startFormat} - ${_endFormat}`;
                }
                break;
            case 'week':
                {
                    const _startFormat = getUtcFormattedTime(_startUTCDate, 'dd MMM');
                    const _endFormat = getUtcFormattedTime(_endUTCDate, defaultDateFormat);
                    formattedTitle = `${_startFormat} - ${_endFormat}`;
                }
                break;
            case 'day':
                {
                    const _currentDateUTC = parseISO(new Date(options?.date?.marker).toISOString());
                    const _format = getUtcFormattedTime(_currentDateUTC, defaultDateFormat);
                    formattedTitle = `${_format}`;
                }
                break;
        }
        return formattedTitle;
    }, []);

    return (
        <div className="event-calendar-page">
            <PageHeader
                pageTitle={<span style={{ marginRight: '10px' }}>Events</span>}
                extra={[<ViewSwitcher key="event-view-switcher" viewOptions={eventViewOptions()} view={view} handleViewMode={handleViewMode} />]}
                actions={[
                    auth.user?.isAdminRoleType() && (
                        <MarketZoneSelector
                            includeOther
                            key="zone-selector"
                            value={pageState?.market && pageState?.load_zone ? [pageState?.market, pageState?.load_zone] : []}
                            onChange={handleMarketLoadZone}
                            disabled={pageState?.programId}
                        />
                    ),
                    <ProgramSelector
                        selectedProgram={pageState?.programId || null}
                        handleProgram={handleProgramChange}
                        disabled={pageState?.load_zone}
                        allowClear={true}
                        placeholder="Select program"
                        key="program-selector"
                        data-cy="program-selector"
                    />,
                    <SearchInput onSearch={handleSearch} defaultValue={pageState?.search} key="search-input" />,
                    ability.can('create', 'Event') && (
                        <WithControlledTooltip
                            extraVisibleCondition={!isResourcesSelected}
                            title="Please select at least one site from the left section of the calendar!"
                            key="new-event"
                        >
                            <Button
                                style={!isResourcesSelected ? { pointerEvents: 'none' } : {}}
                                size="large"
                                type="primary"
                                onClick={showNewEventForm}
                                icon={<PlusCircleOutlined />}
                                disabled={!isResourcesSelected}
                                data-cy="create-event"
                            >
                                New Event
                            </Button>
                        </WithControlledTooltip>
                    ),
                    ability.can('create:batchByProgram', 'Event') && (
                        <Button
                            size="large"
                            type="primary"
                            onClick={showEventByProgramForm}
                            icon={<PlusCircleOutlined />}
                            data-cy="create-event-by-program"
                            key="create-event-by-program"
                        >
                            Dispatch by program
                        </Button>
                    ),
                ]}
            />

            <Card size="small" className="event-calendar" id="eventCalendar">
                <Spin tip="Loading..." spinning={isFetching}>
                    <FullCalendar
                        nowIndicator
                        ref={calRef}
                        views={{
                            month: {
                                titleFormat: options => getCalendarTitle(options, 'month'),
                            },
                            week: {
                                titleFormat: options => getCalendarTitle(options, 'week'),
                            },
                            day: {
                                titleFormat: options => getCalendarTitle(options, 'day'),
                            },
                        }}
                        schedulerLicenseKey={config.FULL_CALENDER_LICENSE_KEY}
                        plugins={[dayGridPlugin, resourceTimelinePlugin, interactionPlugin]}
                        datesSet={handleDatesSet}
                        initialView="resourceTimelineDay"
                        eventMinWidth={5}
                        aspectRatio={1.8}
                        scrollTime="09:00"
                        height="calc(100vh - 310px)"
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short',
                        }}
                        headerToolbar={{
                            left: 'today prev,next',
                            center: 'title hint',
                            right: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth',
                        }}
                        customButtons={{
                            hint: {
                                text: `Events on this timeline are displayed relative to the current user's local computer time, but events are dispatched at each site's local timezone`,
                                click: () => {},
                            },
                        }}
                        eventClick={ability.can('update', 'Event') ? onEventClick : undefined}
                        eventBorderColor="#EEE"
                        resourceGroupField="site"
                        resourceAreaWidth="40%"
                        resourceOrder="company, _market_load_zone, site"
                        resourceAreaColumns={resourceAreaColumns}
                        resources={resources}
                        events={transformedEventsToCalendarView as any}
                        resourceGroupLabelContent={resourceGroupLabelContentRenderer}
                        resourceLabelContent={resourceLabelContentRenderer}
                        eventContent={eventContentHandler}
                    />
                </Spin>

                <EventLegendOverlay />
            </Card>

            {event && emitBy === EventCalendarEmitter.SITE && (
                <EventBySiteFormModal event={event} resources={getSelectedResources(resources)} onClose={onEventFormClose} isEditMode={isEditMode} />
            )}

            {event && emitBy === EventCalendarEmitter.PROGRAM && FORM_MODE.NEW && (
                <EventByProgramFormModal event={event} onClose={onEventFormClose} />
            )}
        </div>
    );
};

function getMarketLoadZoneLabel(resource: Partial<IResource>): string {
    let marketLoadZone = '';
    const market = resource.lmp_market ? resource.lmp_market : '-';
    const loadZone = resource.site_load_zone ? `${resource.site_load_zone}` : '-';

    if (loadZone) {
        marketLoadZone = `${market}, ${loadZone}`;
    }

    if (market !== '-' && !loadZone) {
        marketLoadZone = market;
    }

    return marketLoadZone;
}

function hideEmptyMarketOrLoadZoneLabel(groupLabel: string): string {
    let label = groupLabel;

    if (label.includes(NO_MARKET_TEXT)) {
        label = label.replace(NO_MARKET_TEXT, '');
    }

    if (label.includes(NO_LOAD_ZONE_TEXT)) {
        label = label.replace(`, ${NO_LOAD_ZONE_TEXT}`, '');
    }

    return label;
}
