import { notification } from 'antd';
import { ExportButton } from '../ExportButton/ExportButton';
import { exportMeterEnrollments, exportMeters, exportSites } from '../../domain/site';
import { useAuth } from '../../domain/auth/useAuth';
import { useState } from 'react';
import { DownloadSiteCsvModal, IDownloadSiteCsvFormData } from './DownloadSiteCsvModal';
import { useColumns } from '../../layouts/private/context/ColumnsContext';
import { ColumnsType } from 'antd/lib/table/interface';

interface ISiteExportButtonProps {
    filter: {
        [key: string]: any;
    };
    [key: string]: any;
}

const TABLE_KEYS_TO_CSV_COLUMNS_MAP: { [key: string]: string } = {
    company_id: 'company__company_name',
    site_name: 'site_name',
    control_provider_id: 'control_provider__company_name',
    utility_customer_id: 'utility_customer__company_name',
    address: 'site_address',
    site_store_number: 'site_store_number',
    site_utility: 'site_utility',
    program_id: 'programs',
    lmp_market: 'lmp_market,site_load_zone',
    sans: 'site_service_account_number',
    status: 'status',
    interval_data: 'interval_data_connection,latestIntervalDataDate',
    exist_salesforce_meters: 'meters',
    exist_salesforce_meter_enrollments: 'meterEnrollments',
};

export const SiteEnrollmentExportButton = ({ filter, ...props }: ISiteExportButtonProps) => {
    const auth = useAuth()!;
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const { columns } = useColumns();

    const exportSitesCSVAction = async (data: IDownloadSiteCsvFormData) => {
        try {
            setExportLoading(true);
            await exportSites({
                ...(data.applyFilters ? filter : {}),
                fileColumnsPreset: data.columnsPreset,
                splitEnrollments: data.splitByEnrollments,
                include: auth.user?.isAdminRoleType()
                    ? 'company,control_provider,utility_customer,program,san,meter,meter_enrollment'
                    : 'company,control_provider,utility_customer,program,san',
                ...(data.columnsPreset === 'limited' ? { fields: getFieldsList(columns) } : {}),
            });
        } catch (error: any) {
            notification.error({
                key: 'sites-export-csv-error',
                message: error.message || 'Cannot export sites file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const exportMeterEnrollmentCSVAction = async () => {
        try {
            setExportLoading(true);
            await exportMeterEnrollments(filter);
        } catch (error: any) {
            notification.error({
                key: 'meter-enrollment-export-csv-error',
                message: error.message || 'Cannot export meter enrollments file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const exportMeterCSVAction = async () => {
        try {
            setExportLoading(true);
            await exportMeters(filter);
        } catch (error: any) {
            notification.error({
                key: 'meter-export-csv-error',
                message: error.message || 'Cannot export meters file!',
            });
        } finally {
            setExportLoading(false);
        }
    };

    const handleSubmit = async (data: IDownloadSiteCsvFormData) => {
        setPopupOpen(false);

        switch (data.fileType) {
            case 'site':
                await exportSitesCSVAction(data);
                break;

            case 'meter':
                await exportMeterCSVAction();
                break;

            case 'meter-enrollment':
                await exportMeterEnrollmentCSVAction();
                break;

            default:
                console.log('Unknown file type: ', data.fileType);
        }
    };

    const openSiteModal = () => setPopupOpen(true);

    const actions = [
        {
            key: 'e-b-1',
            title: 'Download CSV',
            fn: openSiteModal,
        },
    ];

    return (
        <>
            <ExportButton key="site-export" actions={actions} loading={exportLoading} {...props} />
            {popupOpen && <DownloadSiteCsvModal onClose={() => setPopupOpen(false)} onSubmit={handleSubmit} />}
        </>
    );
};

function getFieldsList(tableColumns: ColumnsType<any>): string {
    const csvColumns: string[] = [];

    tableColumns.forEach(column => {
        const isColumnHidden = !!column.hidden;
        if (!isColumnHidden && typeof column.key === 'string' && TABLE_KEYS_TO_CSV_COLUMNS_MAP[column.key]) {
            csvColumns.push(TABLE_KEYS_TO_CSV_COLUMNS_MAP[column.key]);
        }
    });

    return csvColumns.join(',');
}
