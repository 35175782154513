import { Resizable } from 're-resizable';

export const ResizableColumnTitle = (props: any) => {
    const { width, title, columnIndex, onColumnResize, ...restProps } = props;

    if (!title) {
        return <th {...restProps}></th>;
    }

    const handleResizeStop = (e: any, direction: any, ref: any, d: any) => {
        onColumnResize(columnIndex, d.width);
    };

    return (
        <th>
            <Resizable
                size={{ width }}
                minWidth={50}
                onResizeStop={handleResizeStop}
                handleClasses={{
                    right: 'resizable-handle',
                }}
                enable={{ right: true }}
            >
                <div {...restProps}></div>
            </Resizable>
        </th>
    );
};
