import { useQuery } from '@tanstack/react-query';
import { QueryKey } from 'src/query-client';
import { API } from '../api';
import { IPagination } from '../IPagination';
import { IAuditTrail, IFetchAuditTrail } from './interface';
import { SORT_ORDER } from '../commonConst';

const fetchAuditTrail = async (
    { filter, pagination, sorter, search }: IFetchAuditTrail,
    signal?: AbortSignal | null
): Promise<IPagination<IAuditTrail>> => {
    const limit = pagination?.pageSize || 10;
    const current = pagination?.current || 1;
    const offset = (current - 1) * limit;

    if (!sorter || !sorter.field || !sorter.order) {
        sorter = {
            field: 'datetime',
            order: SORT_ORDER.DESCEND,
        };
    }

    const path = `/audit-trail?${new URLSearchParams({
        ...(filter?.action && { action: filter.action }),
        ...(filter?.entity_id && { entityId: filter.entity_id.toString() }),
        ...(filter?.entity_type && { entityType: filter.entity_type }),
        ...(search && { search }),
        ...(sorter?.field && { sortField: sorter.field, sortOrder: sorter.order }),
        offset: offset.toString(),
        limit: limit.toString(),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot fetch data!`);
};

export const useAuditTrailListQuery = (query: IFetchAuditTrail, options = {}) => {
    return useQuery<IPagination<IAuditTrail>, Error>({
        queryKey: [QueryKey.AUDIT_TRAIL, query],
        queryFn: ({ signal }) => fetchAuditTrail(query, signal),
        ...options,
    });
};

const fetchRecentAuditTrail = async ({ filter, search }: IFetchAuditTrail, signal?: AbortSignal | null): Promise<IPagination<IAuditTrail>> => {
    const path = `/audit-trail/recent?${new URLSearchParams({
        ...(filter?.action && { action: filter.action }),
        ...(filter?.entity_id && { entityId: filter.entity_id.toString() }),
        ...(filter?.entity_type && { entityType: filter.entity_type }),
        ...(search && { search }),
    })}`;

    const response = await API.fetch(path, {
        ...(signal && { signal }),
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const respBody: any = await response.json();

    if (response.ok) {
        return respBody;
    }

    throw new Error(respBody?.error?.message || `Cannot fetch data!`);
};

export const useRecentAuditTrailListQuery = (query: IFetchAuditTrail, options = {}) => {
    return useQuery<IPagination<IAuditTrail>, Error>({
        queryKey: [QueryKey.AUDIT_TRAIL_RECENT, query],
        queryFn: ({ signal }) => fetchRecentAuditTrail(query, signal),
        ...options,
    });
};
