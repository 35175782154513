import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { ISite, PARTNER_ID_ERROR_STATUS, SiteIntervalDataFilters, SiteStatus } from '../../domain/site/interface';
import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { ICustomerVen } from '../../domain/ven/interface';
import config from '../../config';

export const handleLettersNumberInput = (event: any) => {
    const charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^\d+$/)) event.preventDefault();
};

export const newSite = {
    site_id: undefined,
    company_id: undefined,
    company: undefined,
    programs: [],
    google_place_id: null,
    site_name: undefined,
    lmp_node_name: undefined,
    site_address: undefined,
    site_city: undefined,
    site_state: undefined,
    site_zip: '',
    site_country: undefined,
    site_lat: null,
    site_long: null,
    site_timezone: undefined,
    site_store_number: undefined,
    site_estimated_kw: 0,
    site_slap: undefined,
    site_utility: undefined,
    site_vertical: undefined,
    site_market_resource: '',
    control_provider_id: undefined,
    event_offset: 0,
    site_highlight: false,
    email_only_integration: false,
    partner_id_status: null,
};

export const prepareVen = (site: ISite): Partial<ICustomerVen> => {
    if (site.customer_ven) {
        return { ...site.customer_ven, site: site };
    }

    let customer_ven = {
        site_id: site.site_id,
        company_id: site.company_id,
        site: site!,
        ven_username: undefined,
        ven_password: undefined,
        ven_id: undefined,
        ven_uri: `${config.HOST}/api/vtn`,
        ven_vtn_id: 'ENERSPONSE_VTN',
        ven_cert: undefined,
        ven_default_event_signal: undefined,
        ven_group_id: undefined,
        ven_key: undefined,
        ven_market_context: 'enersponse',
        ven_party_id: undefined,
        ven_profile: '2.0a',
        ven_registration_id: undefined,
        ven_resource_id: undefined,
        mac_address: undefined,
    };

    return customer_ven;
};

export const PARTNER_ID_ERROR_STATUS_ICON = {
    [PARTNER_ID_ERROR_STATUS.ERROR]: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />,
    [PARTNER_ID_ERROR_STATUS.WARNING]: <WarningOutlined style={{ color: '#fa8c16' }} />,
    [PARTNER_ID_ERROR_STATUS.SUCCESS]: null,
    [PARTNER_ID_ERROR_STATUS.SOLVED]: null,
};

export const SITE_INTERVAL_DATA_FILTER_VALUES = [
    { text: 'Receiving Interval Data', value: SiteIntervalDataFilters.RECEIVING_DATA },
    { text: 'Connected With Errors', value: SiteIntervalDataFilters.CONNECTED_WITH_ERRORS },
    { text: 'No Interval Data', value: SiteIntervalDataFilters.NO_INTERVAL_DATA },
];

export const SITE_STATUS_TEXT_MAP: { [key: string]: string } = {
    [SiteStatus.ENROLLED]: 'Enrolled',
    [SiteStatus.DE_ENROLLED]: 'De-Enrolled',
    [SiteStatus.INELIGIBLE_FOLLOW_UP]: 'Ineligible-Follow Up',
    [SiteStatus.SUBMITTED]: 'Submitted',
    [SiteStatus.PRE_SUBMISSION]: 'Pre-Submission',
    [SiteStatus.NOT_PARTICIPATING]: 'Not Participating',
    [SiteStatus.INELIGIBLE_FINAL]: 'Ineligible-Final',
    [SiteStatus.PR_CR]: 'PR/CR',
    [SiteStatus.ERROR]: 'Error',
};

export const SITE_STATUS_FILTER_VALUES = [
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.ENROLLED], value: SiteStatus.ENROLLED },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.DE_ENROLLED], value: SiteStatus.DE_ENROLLED },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.INELIGIBLE_FOLLOW_UP], value: SiteStatus.INELIGIBLE_FOLLOW_UP },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.SUBMITTED], value: SiteStatus.SUBMITTED },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.PRE_SUBMISSION], value: SiteStatus.PRE_SUBMISSION },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.NOT_PARTICIPATING], value: SiteStatus.NOT_PARTICIPATING },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.INELIGIBLE_FINAL], value: SiteStatus.INELIGIBLE_FINAL },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.PR_CR], value: SiteStatus.PR_CR },
    { text: SITE_STATUS_TEXT_MAP[SiteStatus.ERROR], value: SiteStatus.ERROR },
];
