import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart } from 'echarts/charts';
import { EChartsOption } from 'echarts';
import { DataZoomComponent, GridComponent, MarkAreaComponent, MarkLineComponent, TooltipComponent, ToolboxComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { FC } from 'react';
import { merge } from 'lodash';
import { formatInTimeZone } from 'date-fns-tz';
import { EChartsInstance } from 'echarts-for-react';

echarts.use([
    LineChart,
    BarChart,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    CanvasRenderer,
    MarkAreaComponent,
    MarkLineComponent,
    DataZoomComponent,
]);

const groupId = 'plf-group-id';
const echartsInstanceStore = {} as Record<string, EChartsInstance>;

function onChartReady(instance: EChartsInstance) {
    // Set group id and add new echarts instance to the store
    if (instance) {
        if (!echartsInstanceStore[instance.id]) {
            instance.group = groupId;
            echartsInstanceStore[instance.id] = instance;
        }
    }

    // Remove echarts instance when it is null
    for (const [key, value] of Object.entries(echartsInstanceStore)) {
        if (!value) {
            delete echartsInstanceStore[key];
        }
    }

    // Enable interaction synchronization for multiple charts
    if (Object.entries(echartsInstanceStore).length > 1) {
        echarts.disconnect(groupId);
        echarts.connect(groupId);
    }
}

export type PlfChartProps = {
    showLoading: boolean;
    option: EChartsOption;
    timezone: string;
    style?: React.CSSProperties | undefined;
};

export const PlfChart: FC<PlfChartProps> = ({ showLoading, option, timezone, style, ...props }) => {
    const defaultOption: EChartsOption = {
        grid: {
            top: '10px',
            left: '80px',
            right: '70px',
            bottom: '70px',
        },
        toolbox: {
            show: false,
            feature: {},
        },
        tooltip: {
            trigger: 'item',
            position: 'top',
            padding: 0,
            borderColor: '#ffffff',
            axisPointer: {
                type: 'cross',
                crossStyle: {
                    type: 'solid',
                },
            },
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
                color: '#697475',
                margin: 12,
                formatter: (datetime: unknown) => {
                    const hours = parseInt(formatInTimeZone(new Date(datetime as string), timezone, 'H'));
                    const hourEnding = hours + 1;
                    return hourEnding.toString();
                },
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#d6dbdb',
                    width: 2,
                    type: 'solid',
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
            },
        },
        yAxis: {
            type: 'value',
            /** we display Y axis size as max value + 10% */
            max: value => {
                if (value.max < 0) {
                    return value.max - value.max * 0.5;
                }
                return value.max + value.max * 0.1;
            },
            min: value => {
                if (value.min >= 0) return 0;
                return value.min + value.min * 0.1;
            },
            axisLabel: {
                color: '#697475',
                showMinLabel: false,
                showMaxLabel: false,
            },
            axisPointer: {
                snap: true,
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#d6dbdb',
                    width: 2,
                    type: 'solid',
                },
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false,
            },
        },
    };

    return (
        <ReactEChartsCore
            echarts={echarts}
            style={style}
            showLoading={showLoading}
            loadingOption={{
                color: 'rgba(116, 215, 213, 0.8)',
            }}
            option={merge(defaultOption, option)}
            onChartReady={onChartReady}
            notMerge
            lazyUpdate
            {...props}
        />
    );
};
