import { useMutation, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../query-client';
import { IAnalyticsChartsData } from '../site/interface';
import { fetchSitesEnergyUsageForCharts, createEnergyUsageRequest, getRecentEnergyUsageRequests } from './index';
import { IEnergyUsageRequest, IFetchSitesEnergyDataParams, IRequestEnergyUsageParams } from './interface';

export const useSitesEnergyUsageForChartsQuery = (query: IFetchSitesEnergyDataParams, options = {}) => {
    return useQuery<IAnalyticsChartsData, Error>({
        queryKey: [QueryKey.ENERGY_USAGES, query],
        queryFn: () => fetchSitesEnergyUsageForCharts(query),
        ...options,
    });
};

export const useCreateRequestEnergyUsageQuery = () => {
    return useMutation<void, Error, IRequestEnergyUsageParams>(createEnergyUsageRequest);
};

export const useRecentEnergyUsageRequestsQuery = (options = {}) => {
    return useQuery<IEnergyUsageRequest[], Error>({
        queryKey: [QueryKey.ENERGY_USAGES_REQUESTS],
        queryFn: () => getRecentEnergyUsageRequests(),
        ...options,
    });
};
