import { UploadOutlined, SyncOutlined, InfoCircleOutlined } from '@ant-design/icons';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Dropdown from 'antd/lib/dropdown';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import type { MenuProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import Skeleton from 'antd/lib/skeleton';
import { endOfDay, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { addDays } from 'date-fns';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbilityContext } from '../../../components/ability/can';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { DatePeriodSelectorWithPresets } from '../../../components/selectors/DatePeriodSelectorWithPresets/DatePeriodSelectorWithPresets';
import { useCompanies } from '../../../components/selectors/GlobalCompanySelector/CompanyContext';
import { useDocumentTitle } from '../../../components/useDocumentTitle';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { exportSitesEnergyData } from '../../../domain/energy';
import { useSitesEnergyUsageForChartsQuery } from '../../../domain/energy/queries';
import { IAnalyticsChartsData, ISite } from '../../../domain/site/interface';
import { IDateRange } from '../../interface';
import { PageState, usePageLocation } from '../../usePageState';
import { AnalyticsCharts } from '../components/analytics/AnalyticsCharts';
import { UploadEnergyDataModal } from '../components/analytics/UploadEnergyDataModal';
import { viewNavigationOptions } from './viewNavigationOptions';
import { UploadEnergyDataArchiveModal } from '../components/analytics/UploadEnergyDataArchiveModal';
import { WithControlledTooltip } from 'src/components/withControlledTooltip';
import { ReportingSiteSelector } from 'src/components/selectors/ReportingSiteSelector/ReportingSiteSelector';
import { RequestEnergyUsageModal } from '../components/analytics/RequestEnergyUsageModal';

const toSelectedSites = (state: PageState) => {
    const sites = state?.siteId;
    if (!sites) {
        return [];
    }

    const isOneSite = typeof sites === 'number';
    return isOneSite ? [sites] : sites;
};

const defaultDate = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
};

export const EnergyUsageDataAnalytics = () => {
    useDocumentTitle('Energy Usage Data');

    const { notification } = App.useApp();
    const location = useLocation();
    const navigate = useNavigate();
    const { queryToState, setPageQuery } = usePageLocation();
    const pageState = queryToState(location.search);
    const ability = useContext(AbilityContext);
    const { companyId } = useCompanies()!;

    const [date, setDate] = useState<IDateRange>({
        ...defaultDate,
        ...(pageState?.date && { ...pageState?.date }),
    });

    const [exportLoading, setExportLoading] = useState(false);
    const [, setSelectedSites] = useState<ISite[]>([]);

    const [selectedSitesId, setSelectedSitesId] = useState<number[]>([...toSelectedSites(pageState)]);
    const [modalView, setModalView] = useState<'greenbutton' | 'sharepoint' | 'request-energy-usage' | 'none'>('none');

    const [queryDate, setQueryDate] = useState<IDateRange>({ ...defaultDate });
    const [querySelectedSitesId, setQuerySelectedSitesId] = useState<number[]>([...toSelectedSites(pageState)]);

    const [chartsData, setChartsData] = useState<IAnalyticsChartsData>({
        energyData: [],
        eventData: [],
        energyBaselineData: [],
        energyPerformanceData: [],
    });

    const { isFetching, isError, error, refetch } = useSitesEnergyUsageForChartsQuery(
        {
            siteIds: selectedSitesId,
            date: {
                // Add day and sub day to compensate the difference in time zones
                start: subDays(date.start, 1),
                end: addDays(date.end, 1),
            },
        },
        {
            keepPreviousData: false,
            refetchOnMount: false,
            enabled: false,
            onSuccess: (response: IAnalyticsChartsData) => {
                setChartsData({ ...response });
            },
        }
    );

    if (isError) {
        notification.error({ key: 'fetch-energy-data-error', message: error.message || 'Cannot fetch energy data!' });
    }

    if ((chartsData as any)?.error) {
        notification.error({
            key: 'fetch-energy-data-error',
            message: (chartsData as any)?.error?.message || 'Cannot fetch energy data!',
        });
    }

    const onViewChange = (view: string) => {
        const companyQuery = pageState.companyId ? `?companyId=${pageState.companyId}` : '';
        navigate(`${view}${companyQuery}`);
    };

    const onDateChange = (dateRange: RangePickerProps['value'], formattedDate: [string, string]) => {
        const [startDate, endDate] = formattedDate;
        const selectedDate = { start: new Date(startDate), end: endOfDay(new Date(endDate)) };
        setDate(selectedDate);
        setPageQuery({ ...pageState, date: { start: selectedDate.start.valueOf(), end: selectedDate.end.valueOf() } });
    };

    const onSitesChange = (sitesId: number[], sites: ISite[]) => {
        setSelectedSitesId(sitesId);
        sites && setSelectedSites(sites);
        setPageQuery({ ...pageState, siteId: sitesId });
    };

    const onExportEnergyData = async () => {
        setExportLoading(true);

        try {
            await exportSitesEnergyData({
                siteIds: selectedSitesId,
                date: {
                    start: new Date(date.start),
                    end: new Date(date.end),
                },
            });
        } catch (error: any) {
            if (error.name === 'AbortError') return;
            notification.error({
                key: 'fetch-energy-data-error',
                message: error.message || 'Cannot fetch energy data!',
            });
        }

        setExportLoading(false);
    };

    const triggerDataLoad = () => {
        setQueryDate(date);
        setQuerySelectedSitesId(selectedSitesId);
        refetch();
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setModalView('greenbutton');
    };

    const uploadItems: MenuProps['items'] = [
        {
            label: 'Upload SCE Sharepoint ZIP',
            key: '1',
            onClick: () => setModalView('sharepoint'),
        },
        {
            label: 'Upload Greenbutton XML or CSV',
            key: '2',
            onClick: () => setModalView('greenbutton'),
        },
        ...(ability.can('request', 'EnergyUsage')
            ? [
                  {
                      label: 'Request Update from Utility',
                      key: '3',
                      onClick: () => setModalView('request-energy-usage'),
                  },
              ]
            : []),
    ];

    const uploadMenuProps = {
        items: uploadItems,
    };

    return (
        <>
            <PageHeader
                pageTitle="Energy Usage Data"
                extra={[
                    <ViewSwitcher
                        viewOptions={viewNavigationOptions(ability)}
                        key="energy-usage-data-view-switcher"
                        view={location.pathname}
                        handleViewMode={onViewChange}
                    />,
                ]}
                actions={[
                    <WithControlledTooltip
                        key="company-site-selector-tooltip"
                        title="Please select company first!"
                        extraVisibleCondition={!companyId}
                    >
                        <ReportingSiteSelector
                            disabled={!companyId || isFetching}
                            selectedSitesId={selectedSitesId}
                            companyId={companyId}
                            onChange={onSitesChange}
                            setSelectedSites={setSelectedSites}
                        />
                    </WithControlledTooltip>,
                    <DatePeriodSelectorWithPresets
                        key="date-period-selector"
                        disabled={!companyId || isFetching}
                        onChange={onDateChange}
                        selectedPeriod={date}
                    />,
                    <Button
                        type="primary"
                        size="large"
                        key="reload-data"
                        icon={<SyncOutlined />}
                        onClick={() => triggerDataLoad()}
                        disabled={isFetching || !Boolean(selectedSitesId?.length)}
                        data-cy="reload-data"
                    >
                        Load data
                    </Button>,
                    ability.can('upload', 'EnergyFile') && (
                        <Dropdown.Button
                            size="large"
                            key="upload-data"
                            data-cy="upload-energy-data"
                            menu={uploadMenuProps}
                            onClick={handleButtonClick}
                        >
                            <UploadOutlined /> Manage Energy Data
                        </Dropdown.Button>
                    ),
                    <Button
                        size="large"
                        key="download-csv"
                        onClick={onExportEnergyData}
                        disabled={!chartsData?.energyData?.length || isFetching}
                        icon={<DownloadOutlined />}
                        data-cy="download-data"
                        loading={exportLoading}
                    >
                        Download CSV
                    </Button>,
                ]}
            />
            <div style={{ margin: '-24px 8px 8px 8px' }}>
                <InfoCircleOutlined /> The chart’s data is displayed in your computer’s timezone.
            </div>
            {isFetching ? (
                <Card size="small">
                    <Skeleton loading={isFetching} paragraph active />
                    <Skeleton loading={isFetching} paragraph active />
                </Card>
            ) : (
                <>
                    <AnalyticsCharts date={queryDate} company={companyId} selectedSitesId={querySelectedSitesId} chartsData={chartsData} />
                </>
            )}
            {modalView === 'greenbutton' && <UploadEnergyDataModal onClose={() => setModalView('none')} />}
            {modalView === 'sharepoint' && <UploadEnergyDataArchiveModal onClose={() => setModalView('none')} />}
            {modalView === 'request-energy-usage' && <RequestEnergyUsageModal onClose={() => setModalView('none')} />}
        </>
    );
};
