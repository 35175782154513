import CheckOutlined from '@ant-design/icons/CheckOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import App from 'antd/lib/app';
import { RangePickerProps } from 'antd/lib/date-picker';
import Dropdown from 'antd/lib/dropdown';
import { MenuProps } from 'antd/lib/menu';
import Row from 'antd/lib/row';
import Table from 'antd/lib/table';
import { ColumnsType } from 'antd/lib/table/interface';
import Typography from 'antd/lib/typography';
import { endOfDay, endOfMonth, startOfMonth } from 'date-fns';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MemoizedDatetimePopover } from '../../../components/datetimePopover/datetimePopover';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { DatePeriodSelectorWithPresets } from '../../../components/selectors/DatePeriodSelectorWithPresets/DatePeriodSelectorWithPresets';
import { SelectedSiteFilterButton } from '../../../components/site/SelectedSiteFilterButton';
import { SiteAddress } from '../../../components/site/SiteAddress';
import { SiteMarketAndLoadZone } from '../../../components/site/SiteMarketAndLoadZone';
import { getColumnFilteredValue, getColumnSortOrder } from '../../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { DEFAULT_PAGINATION } from '../../../domain/commonConst';
import { exportEvents } from '../../../domain/event';
import { IEvent, OptType } from '../../../domain/event/interface';
import { prepareEventReqDateParams } from '../../../domain/event/prepareEventReqDateParams';
import { useEventListQuery } from '../../../domain/event/queries';
import { IPaginationMeta } from '../../../domain/IPagination';
import { buildEventProgramTableFilter } from '../../../domain/program/buildEventProgramTableFilter';
import { usePrograms } from '../../../domain/program/usePrograms';
import { useMarketOptions } from '../../../domain/site/useMarketOptions';
import { UserType } from '../../../domain/user/interface';
import { IDateRange } from '../../interface';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { EventsMetrics } from '../components/EventsMetrics/EventsMetrics';
import { EventVenContext } from '../components/EventVenContext/EventVenContext';
import { eventViewOptions } from '../eventInterface';
import '../Events.css';
import { useSiteUtilitiesListQuery } from 'src/domain/site/queries';
import { buildEventUtilityTableFilter } from 'src/domain/program/buildEventUtilityTableFilter';
import { getTablePaginationParams } from 'src/components/table/getTablePaginationParams';
import { useAuth } from 'src/domain/auth/useAuth';

const DEFAULT_PERIOD = {
    start: +startOfMonth(new Date()),
    end: +endOfMonth(new Date()),
};

const DEFAULT_SORTER = {
    field: 'event_datetime_start',
    order: 'descend',
};

export const EventHistoryView = ({ company, handleViewMode, view }: IView) => {
    const auth = useAuth()!;

    const location = useLocation();
    const { notification } = App.useApp();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const { programs } = usePrograms();

    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [date, setDate] = useState<IDateRange>({
        ...DEFAULT_PERIOD,
        ...(pageState?.end && pageState?.start && { start: pageState?.start, end: pageState?.end }),
    });

    const { data: utilitiesData } = useSiteUtilitiesListQuery();
    const utilities = utilitiesData ?? [];

    const listQuery = {
        ...pageState.filter,
        ...prepareEventReqDateParams(date),
        startInRange: true,

        search: pageState?.search,
        siteId: pageState?.siteId,
        companyId: company,

        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        sorter: pageState?.sorter ?? DEFAULT_SORTER,
        include: 'site,company,program',
    };

    const { data, isLoading, isError, error } = useEventListQuery(listQuery, {
        refetchOnMount: 'always',
        keepPreviousData: true,
    });
    const events = data?.data ?? [];
    const meta = data?.meta ?? ({ total: 0 } as IPaginationMeta);

    if (isError) {
        notification.error({ key: 'fetch-events-data-error', message: error.message || 'Cannot fetch events data!' });
    }

    const handleExportAllEvents = async () => {
        setExportLoading(true);

        try {
            await exportEvents({
                ...pageState.filter,
                sorter: pageState.sorter || DEFAULT_SORTER,
                include: 'site,company',
                programId: pageState?.programId,
                companyId: company,
                search: pageState?.search,
            });
        } catch (err: any) {
            notification.error({ key: 'events-export-error', message: err.message || 'Cannot export events!' });
        }

        setExportLoading(false);
    };

    const handleExportEvents = async () => {
        setExportLoading(true);

        try {
            await exportEvents({
                ...pageState.filter,
                sorter: pageState.sorter || DEFAULT_SORTER,
                include: 'site,company',
                programId: pageState?.programId,
                companyId: company,
                search: pageState?.search,

                ...prepareEventReqDateParams(date),
                startInRange: true,
            });
        } catch (err: any) {
            notification.error({ key: 'events-export-error', message: err.message || 'Cannot export events!' });
        }

        setExportLoading(false);
    };

    const marketZoneFilterOptions = useMarketOptions({ includeOther: true });

    function onTableChange(pagination: any, filter: any, sorter: any) {
        const preparedParamsForQuery = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParamsForQuery });
    }

    const columns: ColumnsType<IEvent> = [
        {
            title: 'Start date',
            dataIndex: 'event_datetime_start',
            defaultSortOrder: 'descend',
            width: '210px',
            sorter: (a: any, b: any) => a.event_datetime_start - b.event_datetime_start,
            ...getColumnSortOrder('event_datetime_start', pageState.sorter),
            render: (text, record) => (
                <MemoizedDatetimePopover
                    dateFormat="dd/MMM/yyyy hh:mm a"
                    displayUtc
                    timestamp={new Date(text).valueOf()}
                    timeZone={record.site?.site_timezone!}
                />
            ),
        },
        {
            title: 'Company',
            dataIndex: 'company_id',
            render: (text, record) => <div style={{ maxWidth: '200px' }}> {record?.site?.company?.company_name}</div>,
        },
        {
            title: 'Site',
            dataIndex: 'site_id',
            render: (text, record) => <div style={{ maxWidth: '200px' }}>{record?.site?.site_name}</div>,
        },
        { ...buildEventProgramTableFilter(pageState, programs) },
        {
            title: 'VEN',
            render: (text, record) => <EventVenContext value={record.event_context?.customer_ven} />,
        },
        {
            title: 'Address',
            render: (text, record) => (
                <SiteAddress address={record.site?.site_address} city={record.site?.site_city} state={record.site?.site_state} />
            ),
        },
        { ...buildEventUtilityTableFilter(pageState, utilities) },
        {
            title: 'Market, Load Zone',
            dataIndex: ['market'],
            filterMultiple: false,
            width: 170,
            filters: marketZoneFilterOptions,
            ...getColumnFilteredValue('market', pageState.filter),
            render: (text, record) => (record.site ? <SiteMarketAndLoadZone site={record.site} /> : ''),
        },
        {
            title: 'Opt Type',
            dataIndex: 'event_opt_type',
            width: 100,
            filters: [
                { text: 'new', value: OptType.UNSET },
                { text: 'opt-out', value: OptType.OPT_OUT },
                { text: 'opt-in', value: OptType.OPT_IN },
            ],
            ...getColumnFilteredValue('event_opt_type', pageState.filter),
            filterMultiple: false,
            render: (text, record) => {
                if (record.event_opt_type === OptType.OPT_IN) return `opt-in`;
                if (record.event_opt_type === OptType.OPT_OUT) return `opt-out`;

                return '';
            },
        },
        {
            title: 'Duration',
            dataIndex: 'event_duration',
            render: text => <div data-cy="event-duration-cell"> {timeConvertor(text)}</div>,
        },
        {
            title: 'Canceled',
            align: 'center',
            dataIndex: 'event_cancelled',
            filters: [
                { text: 'Canceled', value: 'true' },
                { text: 'Not canceled', value: 'false' },
            ],
            ...getColumnFilteredValue('event_cancelled', pageState.filter),
            filterMultiple: false,
            render: text => text && <CheckOutlined data-cy="event-canceled" />,
        },
        {
            title: 'Completed',
            align: 'center',
            dataIndex: 'event_completed',
            filters: [
                { text: 'Completed', value: 'true' },
                { text: 'Not Completed', value: 'false' },
            ],
            ...getColumnFilteredValue('event_completed', pageState.filter),
            filterMultiple: false,
            render: (text, record) => record.event_completed && <CheckOutlined />,
        },
    ];

    const handleSearch = async (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    function onSiteFilterClear() {
        setPageQuery({ ...pageState, siteId: undefined });
    }

    const onDateChange = (dateRange: RangePickerProps['value'], formattedDate: [string, string]) => {
        const [startDate, endDate] = formattedDate;
        const selectedDate = { start: new Date(startDate), end: endOfDay(new Date(endDate)) };

        setDate(selectedDate);
        setPageQuery({
            ...pageState,
            start: selectedDate.start.valueOf(),
            end: selectedDate.end.valueOf(),
            ...toUsefulParams({ pagination: DEFAULT_PAGINATION }),
        });
    };

    const exportMenu: MenuProps = {
        items: [
            {
                key: 'export-all-events',
                label: 'Download All History',
                onClick: handleExportAllEvents,
            },
        ],
    };

    return (
        <>
            <PageHeader
                pageTitle="Events"
                extra={[<ViewSwitcher key="event-view-switcher" viewOptions={eventViewOptions()} view={view} handleViewMode={handleViewMode} />]}
                actions={[
                    !!pageState.siteId && (
                        <SelectedSiteFilterButton key="event-history-selected-site-filter" value={pageState.siteId} onClear={onSiteFilterClear} />
                    ),
                    <Typography.Text key="event-history-date-period-label" strong>
                        Select Time Period:
                    </Typography.Text>,
                    <DatePeriodSelectorWithPresets
                        key="event-history-date-period-selector"
                        selectedPeriod={date}
                        onChange={onDateChange}
                        memoized={true}
                        disabled={exportLoading}
                    />,
                    <SearchInput key="event-history-search" onSearch={handleSearch} defaultValue={pageState?.search} />,
                    <Dropdown.Button
                        key="event-history-export"
                        data-cy="download-data"
                        onClick={handleExportEvents}
                        loading={exportLoading}
                        size="large"
                        menu={exportMenu}
                    >
                        {!exportLoading && <DownloadOutlined />} Download CSV
                    </Dropdown.Button>,
                ]}
            />

            {[UserType.CONTROL_PROVIDER, UserType.UTILITY_CUSTOMER].includes(auth.user?.user_type as UserType) === false && (
                <EventsMetrics date={{ start: date.start, end: date.end }} key="event-metrics" />
            )}

            <Row>
                <WithEmptyDataTable condition={isLoading} emptyDataDescription="No Data for Specified Search">
                    <Table
                        size="small"
                        sticky
                        rowKey="event_id"
                        columns={columns}
                        dataSource={events}
                        pagination={getTablePaginationParams(meta)}
                        loading={isLoading}
                        onChange={onTableChange}
                        className="event-history-table"
                    />
                </WithEmptyDataTable>
            </Row>
        </>
    );
};

export function timeConvertor(duration: number) {
    if (!duration) {
        return '0m';
    }

    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    const minutesToFixed = Number.isInteger(minutes) ? minutes % 60 : Number(minutes.toFixed(2));

    const hourWithMinutes = minutesToFixed ? `${hours}h ${minutesToFixed}m` : `${hours}h`;

    return hours ? hourWithMinutes : `${minutesToFixed}m`;
}
