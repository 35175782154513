import App from 'antd/lib/app';
import Table from 'antd/lib/table';
import { ColumnFilterItem, ColumnsType } from 'antd/lib/table/interface';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { AuditEntityType, IAuditTrail } from '../../domain/audit-trail/interface';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { useRecentAuditTrailListQuery } from 'src/domain/audit-trail';
import { compareWithLocale } from 'src/domain/compareWithLocale';
import { castArray, orderBy, uniq } from 'lodash';
import Button from 'antd/lib/button';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Tooltip from 'antd/lib/tooltip';
import { toAllowedStringLength } from 'src/domain/common/formattersToAllowedValueLength';
import Papa from 'papaparse';
import saveAs from 'file-saver';
import { format } from 'date-fns-tz';
import { DATE_FORMAT_CSV } from 'src/domain/commonConst';

interface IPageState {
    filter?: {
        entity_type?: AuditEntityType;
        action?: string;
        actor?: string;
    };
    search?: string;
    pagination?: {
        pageSize: number;
        current: number;
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
}

interface IFilterItemWithTooltip extends ColumnFilterItem {
    title: string;
}

export const RecentlyDeletedObjects: React.FC = () => {
    useDocumentTitle('Deleted Objects Last 30 Days');

    const { notification } = App.useApp();
    const location = useLocation();
    const { queryToState, setPageQuery } = usePageLocation({ parseNumbers: false });
    const pageState: IPageState = queryToState(location.search);

    /**
     * we fetch here all recent data - filtering and pagination is done
     * without refetching data from the API
     */
    const { data, isLoading, isFetching, error, isError } = useRecentAuditTrailListQuery({
        filter: { action: 'remove' },
    });

    const fetchedRecords = data?.data || [];

    if (isError) {
        notification.error({
            key: 'audit-trail-error',
            message: error?.message || 'Cannot fetch data!',
        });
    }

    const records = useMemo(() => filterRecords(fetchedRecords, pageState.filter), [fetchedRecords, pageState.filter]);

    const actorFilterOptions = useMemo(() => buildActorsFilterOptions(fetchedRecords), [fetchedRecords]);
    const entityTypeFilterOptions = useMemo(() => buildEntityTypeFilterOptions(fetchedRecords), [fetchedRecords]);

    const onTableChange = (pagination: any, filter: any, sorter: any) => {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });
        setPageQuery({ ...pageState, ...preparedParams });
    };

    let isExportLoading = false;
    const exportToCsv = () => {
        try {
            exportRecentlyDeletedObjectsToCsv(records);

            notification.success({ message: 'CSV export successful!' });
        } catch (error) {
            notification.error({ message: 'CSV export failed!' });
        }
    };

    const columns: ColumnsType<IAuditTrail> = [
        {
            title: 'Type',
            dataIndex: 'entity_type',
            key: 'entity_type',
            width: 220,
            filterMultiple: false,
            sorter: (a, b) => compareWithLocale(a.entity_type, b.entity_type),
            ...getColumnSortOrder('entity_type', pageState.sorter),
            filters: entityTypeFilterOptions,
            ...getColumnFilteredValue('entity_type', pageState.filter),
            render: (text, record) => record.entity_type,
        },
        {
            title: 'Id',
            width: 200,
            key: 'entity_id',
            render: (text, record) => record.entity_id,
        },
        {
            title: 'Summary',
            key: 'data',
            render: (text, record) => record?.summary || '',
        },
        {
            title: 'User',
            dataIndex: 'actor',
            key: 'actor',
            filters: actorFilterOptions,
            filterMultiple: true,
            filterSearch: (input, record) => (record as IFilterItemWithTooltip)?.title?.toString()?.toLowerCase().includes(input.toLowerCase()),
            ...getColumnFilteredValue('actor', pageState.filter),
            sorter: (a, b) => compareWithLocale(a.actor, b.actor),
            ...getColumnSortOrder('actor', pageState.sorter),
        },
        {
            title: 'Date',
            dataIndex: 'datetime',
            key: 'datetime',
            width: 200,
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
            ...getColumnSortOrder('datetime', pageState.sorter),
            render: (text: string, record) => defaultDateFormat(record.datetime, '-', 'MMM dd, yyyy hh:mm a'),
        },
    ];

    return (
        <div className="audit-trail-page">
            <PageHeader
                pageTitle="Deleted Objects Last 30 Days"
                actions={[
                    <Button size="large" key="download-csv" onClick={exportToCsv} icon={<DownloadOutlined />} loading={isExportLoading}>
                        Download CSV
                    </Button>,
                ]}
            />
            <WithEmptyDataTable condition={isFetching || isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={records}
                    loading={isFetching || isLoading}
                    onChange={onTableChange}
                    pagination={{
                        pageSize: pageState.pagination?.pageSize || 10,
                        current: pageState.pagination?.current || 1,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                />
            </WithEmptyDataTable>
        </div>
    );
};

function buildEntityTypeFilterOptions(data: IAuditTrail[]) {
    const entityTypes = orderBy(uniq(data.map(item => item.entity_type)));
    return entityTypes.map(type => ({
        text: type,
        value: type,
    }));
}

function buildActorsFilterOptions(data: IAuditTrail[]) {
    const actors = orderBy(uniq(data.map(item => item.actor)));
    const options = actors.map(actor => ({
        text: (
            <Tooltip placement="bottom" title={actor}>
                {toAllowedStringLength(actor)}
            </Tooltip>
        ),
        title: actor,
        value: actor,
    }));

    return options;
}

function filterRecords(data: IAuditTrail[], filter: IPageState['filter'] = {}) {
    let filteredData = data;

    if (filter.entity_type) {
        const entityTypes = castArray(filter.entity_type);
        filteredData = filteredData.filter(item => (entityTypes as string[]).includes(item.entity_type));
    }

    if (filter.actor) {
        const actors = castArray(filter.actor);
        filteredData = filteredData.filter(item => (actors as string[]).includes(item.actor));
    }

    return filteredData;
}

const exportRecentlyDeletedObjectsToCsv = (data: IAuditTrail[]) => {
    const csvHeaders = ['Type', 'ID', 'Summary', 'User', 'Date'];

    const csvRows = data.map(item => {
        return [item.entity_type || '', item.entity_id || '', item.summary || '', item.actor || '', item.datetime];
    });

    const csvData = Papa.unparse([csvHeaders, ...csvRows], {
        quotes: true,
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        header: true,
        escapeFormulae: true,
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    saveAs(blob, `recently-deleted-objects-${format(new Date(), DATE_FORMAT_CSV)}.csv`);
};
