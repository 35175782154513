import { InfoCircleOutlined } from '@ant-design/icons';
import WarningFilled from '@ant-design/icons/WarningFilled';
import { App, List, Spin } from 'antd';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import { format, formatDistanceToNow } from 'date-fns';
import { IEnergyUsageRequest } from 'src/domain/energy/interface';
import { useRecentEnergyUsageRequestsQuery } from 'src/domain/energy/queries';
import { PRIMARY_COLOR } from 'src/theme';

export function RecentEnergyUsageRequestsList() {
    const { notification } = App.useApp();

    const refetchIntervalMs = 30000;
    const { data, isFetching, isLoading, isError, error } = useRecentEnergyUsageRequestsQuery({
        keepPreviousData: true,
        refetchInterval: refetchIntervalMs,
    });

    if (isError) {
        notification.error({ key: 'recent-energy-usage-requests-error', message: error?.message || 'Cannot load data!' });
    }

    function makeTag(item: IEnergyUsageRequest) {
        switch (item.status) {
            case 'done':
                return <Tag color="success">{item.status}</Tag>;
            case 'failed':
                return <Tag color="error">{item.status}</Tag>;
            case 'pending':
                return <Tag color="processing">{item.status}</Tag>;
            default:
                return <Tag color="default">{item.status}</Tag>;
        }
    }

    function formatSource(item: IEnergyUsageRequest) {
        switch (item.source) {
            case 'pge':
                return 'PG&E';
            case 'coned':
                return 'ConED';
            default:
                return item.source.toUpperCase();
        }
    }

    if (isLoading) {
        return <Spin />;
    }

    return (
        <>
            <Row justify="space-around" align="middle" style={{ width: '100%' }}>
                <Col span={23}>
                    <div style={{ fontSize: '15px', fontWeight: '700' }}>
                        Recent Requests
                        <Tooltip title="Displays up to 10 most recent requests. Refreshes every 30 seconds.">
                            <InfoCircleOutlined style={{ color: PRIMARY_COLOR, fontSize: '15px', marginLeft: '8px', cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                </Col>
                <Col span={1}>{isFetching && <Spin size="small" />}</Col>
            </Row>
            <List
                bordered={false}
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <Row justify="space-around" align="middle" style={{ width: '100%' }}>
                            <Col span={6}>
                                <div>{formatSource(item)}</div>
                                <div style={{ fontSize: '11px' }} title={'Requested: ' + format(item.createdAt, 'dd/MMM/yyyy hh:mm a') + ' UTC'}>
                                    {formatDistanceToNow(item.createdAt)}
                                </div>
                            </Col>
                            <Col span={12}>
                                {format(item.start, 'dd MMM yyyy')} — {format(item.end, 'dd MMM yyyy')}
                            </Col>
                            <Col span={5} style={{ textAlign: 'center' }}>
                                {makeTag(item)}
                            </Col>
                            <Col span={1} style={{ textAlign: 'center' }}>
                                {item.status === 'failed' && (
                                    <Tooltip title={item.metadata?.error || 'Unknown error'}>
                                        <WarningFilled style={{ fontSize: '14px', color: 'orange', cursor: 'pointer' }} />
                                    </Tooltip>
                                )}
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </>
    );
}
