import { IFetchPageQuery } from '../IFetchQueryOptions';
import { ISite } from '../site/interface';

export enum AuditEntityType {
    SITE = 'SiteEntity',
    USER = 'UserEntity',
    CLOUD_VEN = 'CloudVenEntity',
    CUSTOMER_VEN = 'CustomerVenEntity',
    PROGRAM = 'ProgramEntity',
    METER_ENTITY = 'MeterEntity',
    METER_ENROLLMENT_ENTITY = 'MeterEnrollmentEntity',
    PRICE_RESPONSE_TRIGGER = 'PriceResponseTriggerEntity',
    CLEAN_RESPONSE_TRIGGER = 'CleanResponseTriggerEntity',
}

export enum AuditActions {
    CREATE = 'create',
    UPDATE = 'update',
    REMOVE = 'remove',
    ENROLL_PROGRAM = 'enroll:program',
    ENROLL_PRICE_RESPONSE = 'enroll:priceResponse',
    UNENROLL_PRICE_RESPONSE = 'unenroll:priceResponse',
    ENROLL_CLEAR_RESPONSE = 'enroll:cleanResponse',
    UNENROLL_CLEAR_RESPONSE = 'unenroll:cleanResponse',
    CLOSE_OPEN = 'close-open',
    LINK_PROGRAM = 'link:program',
    APPROVE = 'approve:program',
}

export interface IAuditTrail {
    id: number;
    entity_type: AuditEntityType;
    entity_id: string;
    action: AuditActions;
    actor: string;
    data: any;
    summary?: string;
    datetime: string;
}

export interface IAuditTrailWithSite extends IAuditTrail {
    site: ISite;
}

export interface IFetchAuditTrail extends IFetchPageQuery {
    // action?: string;
    // entity_id?: number | string;
    // entity_type?: AuditEntityType;
}
