import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Typography from 'antd/lib/typography';
import DatePicker from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { useCreateRequestEnergyUsageQuery } from 'src/domain/energy/queries';
import Alert from 'antd/lib/alert';
import { RecentEnergyUsageRequestsList } from './RecentEnergyUsageRequestsList';

interface IRequestEnergyUsageFormData {
    source: 'pge' | 'coned';
    dateRange: Dayjs[];
}

interface IRequestEnergyUsageModalProps {
    onClose: () => void;
}

export const RequestEnergyUsageModal = ({ onClose }: IRequestEnergyUsageModalProps) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm<IRequestEnergyUsageFormData>();
    const { mutateAsync: requestEnergyUsageHistory, isLoading } = useCreateRequestEnergyUsageQuery();

    async function onFormFinish(formData: IRequestEnergyUsageFormData) {
        try {
            const source = formData.source;
            const date = {
                start: formData.dateRange[0].toDate(),
                end: formData.dateRange[1].toDate(),
            };

            await requestEnergyUsageHistory({ source, date });

            form.resetFields();

            notification.info({
                key: 'request-energy-usage-info',
                message: 'Data requested',
            });
        } catch (err: any) {
            notification.error({
                key: 'request-energy-usage-error',
                message: err?.message || 'Cannot request energy usage data',
            });
        }
    }

    const disabledDate = (current: Dayjs) => {
        /** Probably we should not allow to request very old historical data */
        const startDate = dayjs().set('year', 2020).startOf('year');
        const endDate = dayjs().startOf('day');

        return current < startDate || current > endDate;
    };

    const sourceOptions = [
        { label: 'PG&E', value: 'pge' },
        { label: 'CONED', value: 'coned' },
    ];

    return (
        <Modal
            title="Request Update from Utility"
            open
            destroyOnClose
            onCancel={onClose}
            footer={[
                <Button key="request-energy-usage-modal-cancel" onClick={onClose} disabled={isLoading}>
                    Close
                </Button>,
                <Button key="request-energy-usage-modal-submit" type="primary" loading={isLoading} onClick={form.submit}>
                    Request Data
                </Button>,
            ]}
        >
            <Form form={form} name="request-energy-usage-form" preserve={false} layout="vertical" onFinish={onFormFinish}>
                <Alert
                    type="info"
                    message="Informational Notes "
                    description={
                        <p>
                            Automatic historical data requests are currently only available for PG&E and ConEd.
                            <br />
                            <br />
                            Keep in mind that requesting data for most utilities works only in production.
                            <br />
                            <br />
                            We do not support requesting data for individual customers — at least for now. Instead, we request data for all available
                            customers, as this approach is more reliable.
                            <br />
                            <br />
                            It may take some time (from minutes to hours) to receive and process the requested data, so please avoid making frequent
                            re-requests.
                        </p>
                    }
                    showIcon
                />
                <p></p>
                <Form.Item
                    name="source"
                    label={<Typography.Text strong>Request Updated Data from Utility</Typography.Text>}
                    hasFeedback
                    rules={[{ required: true, message: 'Please select utility!' }]}
                >
                    <Select size="large" placeholder="Utility" options={sourceOptions}></Select>
                </Form.Item>

                <Form.Item
                    name="dateRange"
                    label={<Typography.Text strong>Date Range</Typography.Text>}
                    hasFeedback
                    rules={[{ required: true, message: 'Please select date range!' }]}
                >
                    <DatePicker.RangePicker
                        size="large"
                        key="date-range-selector"
                        format="YYYY-MM-DD"
                        allowClear={false}
                        disabledDate={disabledDate}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Form>
            <RecentEnergyUsageRequestsList />
        </Modal>
    );
};
