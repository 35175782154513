import Cascader, { DefaultOptionType } from 'antd/lib/cascader';
import Select from 'antd/lib/select';
import { FC } from 'react';
import { marketLoadZoneToLabel } from 'src/components/site/SiteMarketAndLoadZone';
import { useAuth } from 'src/domain/auth/useAuth';
import { Market } from 'src/domain/market-prices/interface';
import { UserType } from 'src/domain/user/interface';

type PeakLoadMarketAndZoneSelectorProps = {
    key?: string;
    disabled?: boolean;
    className?: string;
    markets: Record<Market, string[]>;
    value: [Market, string];
    onChange: (val: [Market, string]) => void;
};

export const PeakLoadMarketAndZoneSelector: FC<PeakLoadMarketAndZoneSelectorProps> = ({ markets, value, onChange, ...props }) => {
    const auth = useAuth()!;
    const user = auth.user!;

    const [market, loadZone] = value;

    const marketZoneOptions = Object.entries(markets)
        /** ! currently we only support ISONE and NYISO */
        .filter(([market, zones]) => [Market.ISONE, Market.NYISO, Market.ERCOT, Market.PJM].includes(market.toLowerCase() as Market))
        .map(([market, zones]) => ({
            value: market,
            label: market,
            children: filterLoadZones(market as Market, zones)
                .map(zone => ({
                    value: zone,
                    label: marketLoadZoneToLabel(market, zone),
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
        }));

    const filter = (inputValue: string, path: DefaultOptionType[]) =>
        path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    const handleMarketAndZoneChange = (newValue: string[]) => {
        onChange(newValue as [Market, string]);
    };

    /** for Utility Customer user we should display only load zone selector */
    if (user.user_type === UserType.UTILITY_CUSTOMER) {
        const zoneList = markets[market?.toUpperCase() as Market] || [];

        const zoneOptions = zoneList.map(it => {
            return {
                value: it,
                label: it,
            };
        });

        return (
            <Select
                style={{ minWidth: '100px' }}
                showSearch
                allowClear={false}
                placeholder="Select Load Zone"
                options={zoneOptions}
                onChange={val => handleMarketAndZoneChange([market, val as string])}
                size="large"
                value={loadZone}
                {...props}
            />
        );
    }

    /** Admin and Account Manager users can select market and oad zone */
    return (
        <Cascader
            style={{ minWidth: '100px' }}
            allowClear={false}
            size="large"
            placeholder="Select Load Zone"
            showSearch={{ filter }}
            options={marketZoneOptions}
            onChange={val => handleMarketAndZoneChange(val as string[])}
            value={[market.toUpperCase(), loadZone]}
            {...props}
        />
    );
};

function filterLoadZones(market: Market, zones: string[]): string[] {
    const ERCOT_SUPPORTED_ZONES = ['HOUSTON', 'NORTH', 'WEST', 'SOUTH', 'AEN', 'CPS', 'RAYBN', 'LCRA'].map(zone => zone.toLowerCase());
    if (market.toLowerCase() === Market.ERCOT) {
        return zones.filter(zone => ERCOT_SUPPORTED_ZONES.includes(zone.toLowerCase()));
    }

    return zones;
}
