import CheckOutlined from '@ant-design/icons/CheckOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import Button from 'antd/lib/button';
import App from 'antd/lib/app';
import Row from 'antd/lib/row';
import Table, { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns';
import { MemoizedDatetimePopover } from '../../../components/datetimePopover/datetimePopover';
import { PageHeader } from '../../../components/pageHeader/pageHeader';
import { WithEmptyDataTable } from '../../../components/table/withEmptyDataTable';
import { exportTrackingAlerts } from '../../../domain/event';
import { SearchInput } from '../../../components/searchInput/searchInput';
import { useAlertTrackingUsersListQuery, useAlertTrackListQuery } from '../../../domain/alert-track/queries';
import { ITrackingAlert } from '../../../domain/event/interface';
import { toUsefulParams } from '../../toUsefulParams';
import { usePageLocation } from '../../usePageState';
import { ViewSwitcher } from '../../../components/viewSwitcher/ViewSwitcher';
import { IView } from '../../../components/viewSwitcher/viewInterface';
import { DatePeriod, DatePeriodSelector } from '../../../components/selectors/DatePeriodSelector/DatePeriodSelector';
import { IDateRange } from '../../interface';
import { eventViewOptions } from '../eventInterface';
import { dateToUtcLocale } from '../../../domain/common/timeHelpers';
import { TrackingAlertStatusIcon } from '../../../components/TrackingAlertStatusIcon';
import { SUCCESS_COLOR } from '../../../theme';
import { getColumnFilteredValue, getColumnSortOrder } from 'src/components/table/columnFormatHelpers';
import { DEFAULT_PAGINATION } from 'src/domain/commonConst';
import { buildTrackingUsersTableFilter } from '../components/buildTrackingUsersTableFilter';
import { compareWithLocale } from 'src/domain/compareWithLocale';

const DEFAULT_PERIOD = {
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
};

const DEFAULT_SORTER = {
    field: 'event_datetime_start',
    order: 'descend',
};

export const EventTrackingView = ({ company, handleViewMode, view }: IView) => {
    const location = useLocation();
    const { notification } = App.useApp();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);

    const [exportLoading, setExportLoading] = useState(false);
    const [date, setDate] = useState<IDateRange>({
        ...DEFAULT_PERIOD,
        ...(pageState?.end && pageState?.start && { start: pageState?.start, end: pageState?.end }),
    });

    const { data: usersData } = useAlertTrackingUsersListQuery({ companyId: company });
    const users = usersData || [];

    const listQuery = {
        ...pageState.filter,
        sorter: pageState?.sorter ?? DEFAULT_SORTER,

        companyId: company,

        search: pageState?.search,

        start: date.start ? dateToUtcLocale(new Date(date.start)) : undefined,
        end: date.end ? dateToUtcLocale(new Date(date.end)) : undefined,
    };

    const {
        isLoading,
        isError,
        error,
        data: trackingAlerts,
    } = useAlertTrackListQuery(listQuery, {
        refetchOnMount: 'always',
        keepPreviousData: true,
    });

    if (isError) {
        notification.error({
            key: 'alert-track-list-error',
            message: error.message || 'Cannot load alert tracks!',
        });
    }

    const columns: ColumnsType<ITrackingAlert> = [
        { title: 'Company', dataIndex: 'company' },
        { ...buildTrackingUsersTableFilter(pageState, users) },
        { title: 'Email', dataIndex: ['user', 'email'] },
        {
            title: 'Event',
            dataIndex: 'event_datetime_start',
            render: (startDate: string) =>
                startDate ? <MemoizedDatetimePopover timestamp={new Date(startDate).valueOf()} dateFormat="dd/MMM/yyyy hh:mm a" /> : '-',
            sorter: (a, b) => compareWithLocale(a.event_datetime_start, b.event_datetime_start),
            ...getColumnSortOrder('event_datetime_start', pageState.sorter),
        },
        {
            title: 'Alert Event Status',
            dataIndex: 'event_statuses',
            filters: [
                { text: 'Active', value: 'active' },
                { text: 'Cancelled', value: 'cancelled' },
                { text: 'Pre-event', value: 'pre-event' },
                { text: 'Completed', value: 'completed' },
                { text: 'Scheduled', value: 'scheduled' },
            ],
            ...getColumnFilteredValue('event_statuses', pageState.filter || {}),
            render: (text, record) => record.event_status,
        },
        {
            title: 'Sent',
            dataIndex: 'sentAt',
            render: (sendDate: string) =>
                sendDate ? <MemoizedDatetimePopover timestamp={new Date(sendDate).valueOf()} dateFormat="dd/MMM/yyyy hh:mm a" /> : '-',
        },
        {
            title: 'Opened',
            dataIndex: 'is_opened',
            align: 'center',
            width: 100,
            filters: [
                { text: 'Opened', value: 'true' },
                { text: 'Not Opened', value: 'false' },
            ],
            ...getColumnFilteredValue('is_opened', pageState.filter || {}),
            filterMultiple: false,
            render: (text, record) => !!record.openedAt && <CheckOutlined style={{ color: SUCCESS_COLOR }} />,
        },
        {
            title: 'Confirmed',
            dataIndex: 'is_confirmed',
            align: 'center',
            width: 100,
            filters: [
                { text: 'Confirmed', value: 'true' },
                { text: 'Not Confirmed', value: 'false' },
            ],
            ...getColumnFilteredValue('is_confirmed', pageState.filter || {}),
            filterMultiple: false,
            render: (text, record) => <TrackingAlertStatusIcon trackingAlert={record} />,
        },
    ];

    const exportTrackingAlertsAction = async () => {
        setExportLoading(true);
        try {
            await exportTrackingAlerts({
                ...pageState.filter,
                sorter: pageState.sorter || DEFAULT_SORTER,
                companyId: company,
                start: date.start ? dateToUtcLocale(new Date(date.start)) : undefined,
                end: date.end ? dateToUtcLocale(new Date(date.end)) : undefined,
            });
        } catch (error: any) {
            notification.error({
                key: 'events-tracking-alerts-export-error',
                message: error.message || 'Cannot export tracking alerts!',
            });
        }
        setExportLoading(false);
    };

    const onTableChange = (pagination: any, filter: any, sorter: any) => {
        const preparedParamsForQuery = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParamsForQuery });
    };

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    const handleDatePeriodChange = (selectedDate: IDateRange) => {
        setDate(selectedDate);
        setPageQuery({ ...pageState, ...selectedDate });
    };

    return (
        <>
            <PageHeader
                pageTitle="Events"
                extra={[<ViewSwitcher key="event-view-switcher" viewOptions={eventViewOptions()} view={view} handleViewMode={handleViewMode} />]}
                actions={[
                    <SearchInput key="alert-event-search" onSearch={handleSearch} defaultValue={pageState?.search} />,
                    <DatePeriodSelector
                        key="date-period-selector"
                        onChange={handleDatePeriodChange}
                        selectedPeriod={date}
                        disabled={isLoading}
                        optionsType={[DatePeriod.MONTHLY, DatePeriod.QUARTERLY]}
                    />,
                    <Button loading={exportLoading} size="large" key="download-csv" onClick={exportTrackingAlertsAction} icon={<DownloadOutlined />}>
                        Download CSV
                    </Button>,
                ]}
            />
            <Row>
                <WithEmptyDataTable condition={isLoading} emptyDataDescription="No Data for Specified Search">
                    <Table
                        rowKey={makeRowKeyClosure('tracking')}
                        size="small"
                        style={{ width: '100%' }}
                        sticky
                        columns={columns}
                        dataSource={trackingAlerts}
                        loading={isLoading}
                        pagination={{
                            ...pageState.pagination,
                            showSizeChanger: true,
                            size: 'default',
                            defaultPageSize: 100,
                        }}
                        onChange={onTableChange}
                    />
                </WithEmptyDataTable>
            </Row>
        </>
    );
};

function makeRowKeyClosure(prefix: string) {
    let rowIndex = 0;
    return () => `${prefix}-${rowIndex++}`;
}
