import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Table from 'antd/lib/table';
import { ColumnFilterItem, ColumnsType } from 'antd/lib/table/interface';
import Tooltip from 'antd/lib/tooltip';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IntervalDataTag } from '../../components/IntervalDataTag/IntervalDataTag';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { SiteSANs } from '../../components/site/SiteSANs';
import { getColumnFilteredValue, getColumnSortOrder } from '../../components/table/columnFormatHelpers';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { VenIcon } from '../../components/VenIcon/VenIcon';
import { IAuditTrailWithSite } from '../../domain/audit-trail/interface';
import { defaultDateFormat } from '../../domain/common/dateFormatters';
import { toAllowedStringLength } from '../../domain/common/formattersToAllowedValueLength';
import { exportRecentlyCreatedOrClosedSitesReportingToCsv } from '../../domain/site';
import { getActiveSiteSan } from '../../domain/site/getActiveSiteSan';
import { IntervalDataStatus, SiteIntervalDataFilters } from '../../domain/site/interface';
import { useRecentlyCreatedOrClosedSitesReportingQuery } from '../../domain/site/queries';
import { VenStatus } from '../../domain/ven/interface';
import { SITE_INTERVAL_DATA_FILTER_VALUES } from '../sites/siteInterface';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';

interface IPageState {
    filter?: {
        companyId?: string[] | string;
        siteId?: string[] | string;
        enrollmentId?: string | string[];
        intervalDataStatus?: string;
        venStatus?: string;
        action?: string | string[];
        actor?: string | string[];
    };
    pagination?: {
        pageSize: number;
        current: number;
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
}

interface IFilterItemWithTooltip extends ColumnFilterItem {
    title: string;
}

export const RecentlyCreatedOrClosedSitesReporting: React.FC = () => {
    useDocumentTitle('New and Closed Sites');

    const { notification } = App.useApp();
    const location = useLocation();
    const { queryToState, setPageQuery } = usePageLocation({ parseNumbers: false });
    const pageState: IPageState = queryToState(location.search);

    const { data: auditTrails = [], isLoading, isFetching, error, isError } = useRecentlyCreatedOrClosedSitesReportingQuery();

    const [isExportLoading, setIsExportLoading] = useState(false);

    if (isError) {
        notification.error({
            key: 'new-closed-sites-fetch-error',
            message: error?.message || 'Cannot fetch recent site actions!',
        });
    }

    const filteredAuditTrails = useMemo(() => filterAuditTrails(auditTrails, pageState.filter), [auditTrails, pageState.filter]);

    const onTableChange = (pagination: any, filter: any, sorter: any) => {
        const preparedParams = toUsefulParams({ pagination, filter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    };

    const exportToCsv = () => {
        setIsExportLoading(true);

        try {
            exportRecentlyCreatedOrClosedSitesReportingToCsv(filteredAuditTrails);

            notification.success({ message: 'CSV export successful!' });
        } catch (error) {
            notification.error({ message: 'CSV export failed!' });
        } finally {
            setIsExportLoading(false);
        }
    };

    const { companyFilters, siteFilters, enrollmentIdFilters, actorFilters, actionFilters } = useMemo(
        () => buildAuditTrailsTableFilters(auditTrails),
        [auditTrails]
    );

    const columns: ColumnsType<IAuditTrailWithSite> = [
        {
            title: 'Company',
            key: 'companyId',
            filters: companyFilters,
            filterMultiple: true,
            filterSearch: (input, record) => (record as IFilterItemWithTooltip)?.title?.toString()?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => a.site.company!.company_name.localeCompare(b.site.company!.company_name),
            render: (text, auditTrail) => {
                return auditTrail.site?.company?.company_name || '';
            },
            ...getColumnSortOrder('companyId', pageState.sorter),
            ...getColumnFilteredValue('companyId', pageState.filter),
        },
        {
            title: 'Site',
            key: 'siteId',
            filters: siteFilters,
            filterMultiple: true,
            filterSearch: (input, record) => (record as IFilterItemWithTooltip)?.title?.toString()?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => a.site.site_name.localeCompare(b.site.site_name),
            render: (text, auditTrail) => {
                return auditTrail.site?.site_name || '';
            },
            ...getColumnSortOrder('siteId', pageState.sorter),
            ...getColumnFilteredValue('siteId', pageState.filter),
        },
        {
            title: 'Enrollment ID',
            key: 'enrollmentId',
            filters: enrollmentIdFilters,
            filterMultiple: true,
            filterSearch: (input, record) => (record as IFilterItemWithTooltip)?.title?.toString()?.toLowerCase().includes(input.toLowerCase()),
            render: (text, auditTrail) => {
                if (!auditTrail?.site?.sans?.length) {
                    return '';
                }

                return <SiteSANs market={auditTrail.site.lmp_market} utility={auditTrail.site.site_utility} sans={auditTrail.site.sans} />;
            },
            ...getColumnSortOrder('enrollmentId', pageState.sorter),
            ...getColumnFilteredValue('enrollmentId', pageState.filter),
        },
        {
            title: (
                <>
                    Interval Data
                    <br />
                    connection
                </>
            ),
            align: 'center',
            width: 130,
            key: 'intervalDataStatus',
            filterMultiple: false,
            filters: SITE_INTERVAL_DATA_FILTER_VALUES,
            render: (text: string, auditTrail) => {
                if (!auditTrail?.site?.sans) {
                    return '';
                }

                const activeSan = getActiveSiteSan(auditTrail.site);
                return <IntervalDataTag sanInfo={activeSan?.san_info} />;
            },
            ...getColumnSortOrder('intervalDataStatus', pageState.sorter),
            ...getColumnFilteredValue('intervalDataStatus', pageState.filter),
        },
        {
            title: 'VEN status',
            key: 'venStatus',
            filters: Object.keys(VenStatus).map(status => ({ text: capitalize(status), value: status })),
            filterMultiple: false,
            render: (text, auditTrail) => {
                const site = auditTrail.site;
                const siteVens = [...(site.cloud_vens || []), site.customer_ven].filter(ven => !!ven);

                return (
                    <>
                        {siteVens.map(ven => (
                            <div key={ven?.id!} style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                                <VenIcon ven={ven!} />
                                {capitalize(ven?.status)}
                            </div>
                        ))}
                    </>
                );
            },
            ...getColumnSortOrder('venStatus', pageState.sorter),
            ...getColumnFilteredValue('venStatus', pageState.filter),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 100,
            filterMultiple: false,
            filters: actionFilters,
            sorter: (a, b) => a.action.localeCompare(b.action),
            render: (text: string, record) => {
                return <span style={{ textTransform: 'capitalize' }}>{record.action}</span>;
            },
            ...getColumnSortOrder('action', pageState.sorter),
            ...getColumnFilteredValue('action', pageState.filter),
        },
        {
            title: 'User',
            dataIndex: 'actor',
            key: 'actor',
            filters: actorFilters,
            filterMultiple: true,
            filterSearch: (input, record) => (record as IFilterItemWithTooltip)?.title?.toString()?.toLowerCase().includes(input.toLowerCase()),
            sorter: (a, b) => a.actor.localeCompare(b.actor),
            ...getColumnSortOrder('actor', pageState.sorter),
            ...getColumnFilteredValue('actor', pageState.filter),
        },
        {
            title: 'Date',
            dataIndex: 'datetime',
            key: 'datetime',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
            render: (text: string, record) => defaultDateFormat(record.datetime, '-', 'MMM dd, yyyy hh:mm a'),
            ...getColumnSortOrder('datetime', pageState.sorter),
        },
    ];

    return (
        <>
            <PageHeader
                pageTitle="New and Closed Sites Last 30 Days"
                actions={[
                    <Button size="large" key="download-csv" onClick={exportToCsv} icon={<DownloadOutlined />} loading={isExportLoading}>
                        Download CSV
                    </Button>,
                ]}
            />
            <WithEmptyDataTable condition={isFetching || isLoading}>
                <Table
                    size="small"
                    rowKey="id"
                    sticky
                    columns={columns}
                    dataSource={filteredAuditTrails}
                    loading={isFetching || isLoading}
                    onChange={onTableChange}
                    pagination={{
                        pageSize: pageState.pagination?.pageSize || 10,
                        current: pageState.pagination?.current || 1,
                        showSizeChanger: true,
                        size: 'default',
                    }}
                />
            </WithEmptyDataTable>
        </>
    );
};

function buildAuditTrailsTableFilters(auditTrails: IAuditTrailWithSite[]) {
    const companies = orderBy(
        uniqBy(
            auditTrails.map(auditTrail => auditTrail.site.company!),
            'company_id'
        ),
        'company_name'
    );

    const sites = orderBy(
        uniqBy(
            auditTrails.map(auditTrail => auditTrail.site),
            'site_id'
        ),
        'site_name'
    );

    const enrollmentIds = orderBy(
        uniq(auditTrails.map(auditTrail => auditTrail.site.sans.map(san => san.service_account_number.toString())).flat(1))
    );

    const actors = orderBy(uniq(auditTrails.map(auditTrail => auditTrail.actor)));

    return {
        companyFilters: companies.map(company => ({
            text: (
                <Tooltip placement="bottom" title={company.company_name}>
                    {toAllowedStringLength(company.company_name)}
                </Tooltip>
            ),
            title: company.company_name,
            value: company.company_id.toString(),
        })),

        siteFilters: sites.map(site => ({
            text: (
                <Tooltip placement="bottom" title={site.site_name}>
                    {toAllowedStringLength(site.site_name)}
                </Tooltip>
            ),
            title: site.site_name,
            value: site.site_id.toString(),
        })),

        enrollmentIdFilters: enrollmentIds.map(enrollmentId => ({
            text: (
                <Tooltip placement="bottom" title={enrollmentId}>
                    {toAllowedStringLength(enrollmentId)}
                </Tooltip>
            ),
            title: enrollmentId,
            value: enrollmentId,
        })),

        actorFilters: actors.map(actor => ({
            text: (
                <Tooltip placement="bottom" title={actor}>
                    {toAllowedStringLength(actor)}
                </Tooltip>
            ),
            title: actor,
            value: actor,
        })),

        actionFilters: uniq(auditTrails.map(auditTrail => auditTrail.action)).map(action => ({
            text: capitalize(action),
            value: action,
        })),
    };
}

function filterAuditTrails(auditTrails: IAuditTrailWithSite[], filter: IPageState['filter'] = {}) {
    let filteredData = auditTrails;

    if (filter.companyId) {
        if (typeof filter.companyId === 'string') {
            filteredData = filteredData.filter(auditTrail => auditTrail.site.company?.company_id === parseInt(filter.companyId as string));
        }

        if (Array.isArray(filter.companyId)) {
            filteredData = filteredData.filter(auditTrail =>
                (filter.companyId as string[]).includes(auditTrail.site.company?.company_id?.toString()!)
            );
        }
    }

    if (filter.siteId) {
        if (typeof filter.siteId === 'string') {
            filteredData = filteredData.filter(auditTrail => auditTrail.site.site_id === parseInt(filter.siteId as string));
        }

        if (Array.isArray(filter.siteId)) {
            filteredData = filteredData.filter(auditTrail => (filter.siteId as string[]).includes(auditTrail.site.site_id.toString()));
        }
    }

    if (filter.enrollmentId) {
        const enrollmentIds = Array.isArray(filter.enrollmentId) ? filter.enrollmentId : [filter.enrollmentId];

        filteredData = filteredData.filter(auditTrail =>
            auditTrail.site.sans?.some(san => enrollmentIds.some(id => san.service_account_number.toString() === id.toString()))
        );
    }

    if (filter.intervalDataStatus) {
        if (filter.intervalDataStatus === SiteIntervalDataFilters.RECEIVING_DATA) {
            filteredData = filteredData.filter(auditTrail => {
                const activeSan = getActiveSiteSan(auditTrail.site);
                const intervalDataState = activeSan?.san_info?.intervalDataState;

                return intervalDataState?.status === IntervalDataStatus.CONNECTED;
            });
        }

        if (filter.intervalDataStatus === SiteIntervalDataFilters.NO_INTERVAL_DATA) {
            filteredData = filteredData.filter(auditTrail => {
                const activeSan = getActiveSiteSan(auditTrail.site);
                const sanInfo = activeSan?.san_info;
                const intervalDataState = sanInfo?.intervalDataState;

                return !sanInfo || !intervalDataState || intervalDataState?.status === IntervalDataStatus.UNKNOWN;
            });
        }

        if (filter.intervalDataStatus === SiteIntervalDataFilters.CONNECTED_WITH_ERRORS) {
            filteredData = filteredData.filter(auditTrail => {
                const activeSan = getActiveSiteSan(auditTrail.site);
                const intervalDataState = activeSan?.san_info?.intervalDataState;

                return (
                    intervalDataState?.status === IntervalDataStatus.CONNECTED_BUT_HAS_PROBLEM ||
                    intervalDataState?.status === IntervalDataStatus.INVALID_AGREEMENT
                );
            });
        }
    }

    if (filter.venStatus) {
        filteredData = filteredData.filter(auditTrail => {
            const site = auditTrail.site;
            const siteVens = [...(site.cloud_vens || []), site.customer_ven].filter(ven => !!ven);

            return siteVens.some(ven => ven?.status.toUpperCase() === filter.venStatus);
        });
    }

    if (filter.action) {
        if (typeof filter.action === 'string') {
            filteredData = filteredData.filter(auditTrail => auditTrail.action === filter.action);
        }

        if (Array.isArray(filter.action)) {
            filteredData = filteredData.filter(auditTrail => (filter.action as string[]).includes(auditTrail.action));
        }
    }

    if (filter.actor) {
        if (typeof filter.actor === 'string') {
            filteredData = filteredData.filter(auditTrail => auditTrail.actor === filter.actor);
        }

        if (Array.isArray(filter.actor)) {
            filteredData = filteredData.filter(auditTrail => (filter.actor as string[]).includes(auditTrail.actor));
        }
    }

    return filteredData;
}
