import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import RobotOutlined from '@ant-design/icons/RobotOutlined';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import App from 'antd/lib/app';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import MenuItem from 'antd/lib/menu/MenuItem';
import Table from 'antd/lib/table';
import { ColumnsType, ColumnType, Key } from 'antd/lib/table/interface';
import without from 'lodash/without';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbilityContext } from '../../components/ability/can';
import { ConnectSVG, MaintenanceSVG } from '../../components/icons/icons';
import { IntervalDataTag } from '../../components/IntervalDataTag/IntervalDataTag';
import { AuditTrailModal } from '../../components/modals/AuditTrailModal/AuditTrailModal';
import { PageHeader } from '../../components/pageHeader/pageHeader';
import { SyncSalesforceButton } from '../../components/salesforce/SyncSalesforceButton/SyncSalesforceButton';
import { SearchInput } from '../../components/searchInput/searchInput';
import { useCompanies } from '../../components/selectors/GlobalCompanySelector/CompanyContext';
import { filterCompaniesByType } from '../../components/selectors/GlobalCompanySelector/companyHelpers';
import { AttachProgramBatchModal } from '../../components/site/AttachProgramBatchModal';
import { BatchUnenrollProgramsModal } from '../../components/site/BatchUnenrollProgramsModal';
import { CloseSiteModal } from '../../components/site/CloseSiteModal';
import { DeleteSiteModal } from '../../components/site/DeleteSiteModal';
import { EnrollProgramModal } from '../../components/site/EnrollProgramModal';
import { ExtraSiteInformation } from '../../components/site/ExtraSiteInformation';
import { ImportSitesModal } from '../../components/site/ImportSitesModal/ImportSitesModal';
import { MergeSitesModal } from '../../components/site/merge-sites/MergeSitesModal';
import { useCheckIfAllowedToMergeSites } from '../../components/site/merge-sites/useCheckIfAllowedToMergeSites';
import { OpenSiteModal } from '../../components/site/OpenSiteModal';
import { PartnerIdStatus } from '../../components/site/PartnerIdStatus';
import { SiteAddress } from '../../components/site/SiteAddress';
import { SiteEnrollmentExportButton } from '../../components/site/SiteEnrollmentExportButton';
import { SiteFormModal } from '../../components/site/SiteFormModal';
import { SiteMarketAndLoadZone } from '../../components/site/SiteMarketAndLoadZone';
import { SiteName } from '../../components/site/SiteName';
import { SiteSANs } from '../../components/site/SiteSANs';
import { UpdateSitesBatchModal } from '../../components/site/UpdateSitesBatchModal';
import { ActionList } from '../../components/table/actionList/actionList';
import {
    buildControlProviderFilter,
    buildUtilityCustomerFilter,
    getColumnFilteredValue,
    getColumnSortOrder,
} from '../../components/table/columnFormatHelpers';
import { getTablePaginationParams } from '../../components/table/getTablePaginationParams';
import { useTableSelection } from '../../components/table/useTableSelection';
import { WithEmptyDataTable } from '../../components/table/withEmptyDataTable';
import { ResizableColumnTitle } from '../../components/table/resizableColumnTitle';
import { useDocumentTitle } from '../../components/useDocumentTitle';
import { JobName } from '../../domain/async-job/interface';
import { AuditEntityType } from '../../domain/audit-trail/interface';
import { useAuth } from '../../domain/auth/useAuth';
import { DEFAULT_PAGINATION, PAGE_ACTION } from '../../domain/commonConst';
import { COMPANY_TYPE } from '../../domain/company/interface';
import { compareWithLocale } from '../../domain/compareWithLocale';
import { IPaginationMeta } from '../../domain/IPagination';
import { parseBoolean } from '../../domain/parseBoolean';
import { buildProgramTableFilter } from '../../domain/program/buildProgramTableFilter';
import { useProgramListByCompanyQuery, useProgramListQuery } from '../../domain/program/queries';
import { buildSiteUtilityTableFilter } from '../../domain/site/buildSiteUtilityTableFilter';
import { getActiveSiteSan } from '../../domain/site/getActiveSiteSan';
import { hasActualIntervalData } from '../../domain/site/hasActualIntervalData';
import { ISite, SITE_ACTION, SPECIFIC_SITE_ACTION } from '../../domain/site/interface';
import { useSiteDeleteMutation, useSiteListQuery, useSiteUtilitiesListQuery } from '../../domain/site/queries';
import { useMarketOptions } from '../../domain/site/useMarketOptions';
import { UserType } from '../../domain/user/interface';
import { validatePagination } from '../../domain/validatePagination';
import { DISABLED_COLOR, PRIMARY_COLOR } from '../../theme';
import { toUsefulParams } from '../toUsefulParams';
import { usePageLocation } from '../usePageState';
import { CustomerVenFormModal } from '../ven/components/CustomerVenFormModal';
import { newSite, prepareVen, SITE_INTERVAL_DATA_FILTER_VALUES, SITE_STATUS_FILTER_VALUES } from './siteInterface';
import './sites.css';
import { SidebarFilterType, useSidebar } from 'src/components/Sidebar/context/SidebarContext';
import { omit } from 'lodash';
import { useColumns } from '../../layouts/private/context/ColumnsContext';
import { useSidebarEnabled } from 'src/components/Sidebar/hooks/SidebarEnabled.hook';
import { buildSiteStatusTableFilter, getStatusFilterValue } from '../../domain/site/buildSiteStatusTableFilter';
import { safeStringify } from 'src/domain/common/stringifyHelpers';

export function Sites() {
    const { notification } = App.useApp();
    const location = useLocation();
    const navigation = useNavigate();
    const { setPageQuery, queryToState } = usePageLocation();
    const pageState: any = queryToState(location.search);
    const auth = useAuth()!;
    const ability = useContext(AbilityContext);
    const { companies, companyId } = useCompanies()!;
    const { setResultAmountValue, setFilters, setOptions } = useSidebar();

    const { columns, setColumns, countHiddenColumns } = useColumns();
    const hiddenColumnsCount = countHiddenColumns();

    const { sidebarEnabled } = useSidebarEnabled();
    useDocumentTitle('Sites');

    const isClosed = parseBoolean(pageState.filter?.isClosed);

    const [site, setSite] = useState<ISite | Partial<ISite>>();
    const { selectedKeys: selectedSiteIds, selectedItems: selectedSites, handleSelectedItems } = useTableSelection('site_id');

    const isAllowedToMerge = useCheckIfAllowedToMergeSites(selectedSites);

    const [siteAction, setSiteAction] = useState<SITE_ACTION | ''>();

    const { data: programsData } = useProgramListQuery({});
    const programs = useMemo(() => programsData?.data || [], [programsData?.data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterPrograms = useProgramListByCompanyQuery({ company_id: companyId })?.data || [];
    const programsByCompany = useMemo(() => filterPrograms, [companyId, filterPrograms.length]);
    const programsToDisplay = useMemo(() => (companyId ? programsByCompany : programs), [companyId, programsByCompany]);

    const marketZoneFilterOptions = useMarketOptions({ includeOther: true });
    const controlProviderCompanies = useMemo(() => filterCompaniesByType(companies, COMPANY_TYPE.CONTROL_PROVIDER), [companies]);
    const utilityCustomerCompanies = useMemo(() => filterCompaniesByType(companies, COMPANY_TYPE.UTILITY_CUSTOMER), [companies]);

    const { data: utilitiesData } = useSiteUtilitiesListQuery();
    const utilities = useMemo(() => utilitiesData || [], [utilitiesData]);

    const { mutateAsync: deleteSite, isLoading: isDeleteLoading } = useSiteDeleteMutation();

    const isAdminRole = useMemo(() => auth.user?.isAdminRoleType(), [auth.user?.user_type]);
    const isControlProviderRole = useMemo(() => auth.user?.isControlProvider(), [auth.user?.user_type]);
    const isCompanyColumnHidden = useMemo(() => [UserType.CUSTOMER, UserType.OPERATOR].includes(auth?.user?.user_type!), [auth.user?.user_type]);

    const listQuery = {
        pagination: pageState?.pagination ?? DEFAULT_PAGINATION,
        filter: {
            ...pageState?.filter,
            isClosed,
        },
        ...(pageState?.sorter && { sorter: pageState?.sorter }),
        ...(pageState?.search && { search: pageState?.search }),
        ...(pageState?.companyId && { company_id: pageState?.companyId }),
        include: isAdminRole
            ? 'company,control_provider,utility_customer,program,lmp,customer_ven,meter,meter_enrollment'
            : 'company,control_provider,utility_customer,program,lmp,customer_ven',
    };

    const {
        data,
        isLoading,
        isFetching,
        isError,
        error,
        refetch: refetchSiteList,
    } = useSiteListQuery(listQuery, {
        keepPreviousData: true,
    });
    const sites = data?.data || [];
    const meta =
        data?.meta ??
        ({
            total: 0,
            offset: 0,
            limit: pageState && pageState.pagination && pageState.pagination.pageSize ? pageState.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        } as IPaginationMeta);

    useEffect(() => {
        setResultAmountValue(meta?.total);
    }, [meta?.total, setResultAmountValue]);

    if (isError) {
        notification.error({ key: 'sites-fetch-error', message: error?.message || 'Cannot fetch sites!' });
    }

    function showNewSiteModal() {
        setSiteAction(PAGE_ACTION.NEW_EDIT);
        setSite(newSite);
    }

    const showSiteActionModal = (record: ISite, action: SITE_ACTION) => () => {
        setSiteAction(action);
        setSite({ ...record });
    };

    const toggleClosedSites = async () => {
        setPageQuery({
            ...pageState,
            filter: { ...pageState.filter, isClosed: !isClosed },

            // Reset pagination to the first page
            pagination: {
                ...pageState.pagination,
                current: DEFAULT_PAGINATION.current,
            },
        });
    };

    async function deleteSiteAction(site: ISite) {
        try {
            await deleteSite(site);
            setSite(undefined);

            // remove deleted sites from selected items
            if (selectedSiteIds && selectedSiteIds.includes(site.site_id)) {
                const updatedKeys = without(selectedSiteIds, site.site_id);
                handleSelectedItems(updatedKeys, selectedSites);
            }

            const validPagination = validatePagination(pageState.pagination, meta ? meta.total - 1 : 0);

            setPageQuery({ ...pageState, pagination: validPagination });
            await refetchSiteList({ ...pageState, pagination: validPagination });

            notification.info({ key: 'site-delete-info', message: 'Site deleted' });
        } catch (err: any) {
            notification.error({ key: 'site-delete-error', message: err.message || 'Cannot delete site!' });
        }
    }

    async function showImportModal() {
        setSiteAction(SPECIFIC_SITE_ACTION.IMPORT);
    }

    async function onImportModalClose(shouldRefetch: boolean = false) {
        setSiteAction('');

        if (shouldRefetch) {
            await refetchSiteList(listQuery);
        }
    }

    async function onTableChange(pagination: any, filter: any, sorter: any) {
        // Merge the current pageState.filter with the new table filter,
        // giving precedence to new filter values
        const newFilter = {
            ...pageState.filter,
            ...filter,
            isClosed: parseBoolean(pageState.filter?.isClosed),
        };

        const preparedParams = toUsefulParams({ pagination, filter: newFilter, sorter });

        setPageQuery({ ...pageState, ...preparedParams });
    }

    async function onSiteFormModalClose(site?: Partial<ISite>, closeModal = true) {
        if (closeModal) {
            setSite(undefined);
            setSiteAction('');
        }

        if (site) {
            await refetchSiteList(listQuery);
        }
    }

    async function closeSiteBatchModal(isRefreshNeeded: boolean) {
        setSiteAction(undefined);

        if (isRefreshNeeded) {
            await refetchSiteList(listQuery);
        }
    }

    async function onMergeSitesModalClose(refetch?: boolean) {
        setSiteAction('');

        if (refetch) {
            await refetchSiteList(listQuery);
            handleSelectedItems([], sites);
        }
    }

    async function closeBatchUnenrollModal(isRefreshNeeded: boolean) {
        setSiteAction(undefined);

        if (isRefreshNeeded) {
            await refetchSiteList(listQuery);
        }
    }

    const handleSearch = (value: string) => {
        if (value === pageState.search) {
            setPageQuery({
                ...pageState,
                pagination: { ...pageState.pagination, current: DEFAULT_PAGINATION.current },
            });
            return;
        }

        setPageQuery({ ...pageState, search: value });
    };

    const gotoEventsHistory = (site: ISite) => {
        const companyId = site.company_id;
        const siteId = site.site_id;
        navigation(`/events?companyId=${companyId}&siteId=${siteId}&viewMode=history`);
    };

    const redirectToConnectWebsiteAction = (record: ISite) => () => {
        window.open(record.connect_url, '_blank');
    };

    const actions = [
        ...(ability.can('update', 'Site')
            ? [
                  {
                      title: (site: ISite) => {
                          if (site.closed_at) {
                              return 'To edit the site, please open it first.';
                          }

                          return 'Edit';
                      },
                      onClick: (site: ISite) => {
                          if (site.closed_at) {
                              return null;
                          }

                          return showSiteActionModal(site, PAGE_ACTION.NEW_EDIT);
                      },
                      icon: (site: ISite) => {
                          return <EditOutlined style={{ color: site.closed_at ? DISABLED_COLOR : 'inherit' }} />;
                      },
                  },
              ]
            : []),
        ...(ability.can('delete', 'Site')
            ? [
                  {
                      title: 'Delete',
                      onClick: (record: ISite) => showSiteActionModal(record, PAGE_ACTION.DELETE),
                      dataAction: 'delete',
                  },
              ]
            : []),
        ...(ability.can('close-open', 'Site')
            ? [
                  {
                      title: (record: ISite) => (record.closed_at ? 'Open Site' : 'Close Site'),
                      onClick: (record: ISite) =>
                          showSiteActionModal(record, record.closed_at ? SPECIFIC_SITE_ACTION.OPEN_SITE : SPECIFIC_SITE_ACTION.CLOSE_SITE),
                      icon: <MaintenanceSVG />,
                  },
              ]
            : []),
        ...(ability.can('enroll', 'Program') || ability.can('enroll', 'PriceResponse') || ability.can('enroll', 'CleanResponse')
            ? [
                  {
                      title: (site: ISite) => {
                          if (site.closed_at) {
                              return 'To attach program to the site, please open it first.';
                          }

                          return 'Attach program';
                      },
                      onClick: (site: ISite) => {
                          if (site.closed_at) {
                              return null;
                          }

                          return showSiteActionModal(site, PAGE_ACTION.ENROLL_PROGRAM);
                      },
                      icon: (site: ISite) => {
                          return <ProfileOutlined style={{ color: site.closed_at ? DISABLED_COLOR : 'inherit' }} />;
                      },
                  },
              ]
            : []),
        ...(isAdminRole
            ? [
                  {
                      title: 'Audit Trail',
                      onClick: (record: ISite) => showSiteActionModal(record, PAGE_ACTION.AUDIT_TRAIL),
                      icon: <HistoryOutlined />,
                  },
              ]
            : []),
        ...(ability.can('create', 'CustomerVen')
            ? [
                  {
                      title: (site: ISite) => {
                          if (site.closed_at) {
                              return 'To update VEN, please open it first';
                          }

                          if (site.customer_ven) {
                              return 'Edit VEN';
                          }

                          return 'Add VEN';
                      },
                      onClick: (record: ISite) => {
                          if (record.closed_at) {
                              return null;
                          }

                          return showSiteActionModal(record, SPECIFIC_SITE_ACTION.ADD_VEN);
                      },
                      icon: (record: ISite) => {
                          let color = 'inherit';

                          if (record.customer_ven) {
                              color = PRIMARY_COLOR;
                          }

                          if (record.closed_at) {
                              color = DISABLED_COLOR;
                          }

                          return <RobotOutlined style={{ color }} />;
                      },
                  },
              ]
            : []),
        ...(ability.can('read', 'Event')
            ? [
                  {
                      title: (record: ISite) => 'Open Events History',
                      onClick: (record: ISite) => () => gotoEventsHistory(record),
                      icon: (record: ISite) => <CalendarOutlined />,
                  },
              ]
            : []),
        ...(ability.can('connect', 'Site')
            ? [
                  {
                      title: (record: ISite) => {
                          if (record.can_enroll_interval_data && !hasActualIntervalData(record)) {
                              return 'Connect Site';
                          }

                          if (hasActualIntervalData(record)) {
                              return 'Site is already connected';
                          }

                          return 'Connect Site option is unsupported for this site, please contact the account manager.';
                      },
                      onClick: (record: ISite) =>
                          record?.can_enroll_interval_data && record?.connect_url ? redirectToConnectWebsiteAction(record) : undefined,
                      icon: (record: ISite) => {
                          let iconColor = DISABLED_COLOR;

                          if (record.can_enroll_interval_data) {
                              iconColor = 'inherit';
                          }

                          if (hasActualIntervalData(record)) {
                              iconColor = PRIMARY_COLOR;
                          }

                          return <ConnectSVG style={{ color: iconColor }} />;
                      },
                  },
              ]
            : []),
    ];

    const siteColumns: ColumnsType<ISite> = [
        ...(isCompanyColumnHidden
            ? []
            : [
                  {
                      key: 'company_id',
                      title: 'Company',
                      dataIndex: ['company_id'],
                      width: 85,
                      render: (text: number, record: ISite) => {
                          return (
                              <div style={{ display: 'flex' }}>
                                  <PartnerIdStatus partner_id_status={record.partner_id_status} />
                                  <div style={{ overflow: 'hidden' }}>{record?.company?.company_name}</div>
                              </div>
                          );
                      },
                  },
              ]),
        {
            key: 'site_name',
            title: 'Site Name',
            dataIndex: 'site_name',
            width: 85,
            sorter: (a: ISite, b: ISite) => compareWithLocale(a.site_name, b.site_name),
            ...getColumnSortOrder('site_name', pageState.sorter),
            render: (text, record) => <SiteName site={record} />,
        },
        ...(ability.can('read', 'ControlProvider')
            ? [
                  {
                      key: 'control_provider_id',
                      title: 'Control Provider',
                      dataIndex: 'control_provider_id',
                      sorter: true,
                      ...(!sidebarEnabled
                          ? {
                                filterMultiple: false,
                                filters: buildControlProviderFilter(controlProviderCompanies, {
                                    includeWithoutControlProvider: true,
                                }),
                                ...getColumnFilteredValue('control_provider_id', pageState.filter),
                            }
                          : { filtered: false }),
                      ...getColumnSortOrder('control_provider_id', pageState.sorter),
                      render: (text: string, record: ISite) => record.control_provider?.company_name,
                  },
              ]
            : []),
        ...(ability.can('read', 'UtilityCustomer')
            ? [
                  {
                      key: 'utility_customer_id',
                      title: 'Utility Customer',
                      dataIndex: 'utility_customer_id',
                      sorter: true,
                      ...(!sidebarEnabled
                          ? {
                                filterMultiple: false,
                                filters: buildUtilityCustomerFilter(utilityCustomerCompanies),
                                ...getColumnFilteredValue('utility_customer_id', pageState.filter),
                            }
                          : { filtered: false }),
                      ...getColumnSortOrder('utility_customer_id', pageState.sorter),
                      render: (text: string, record: ISite) => record.utility_customer?.company_name,
                  } as ColumnType<ISite>,
              ]
            : []),
        {
            key: 'site_store_number',
            title: 'Store #',
            width: 90,
            dataIndex: 'site_store_number',
            sorter: (a: ISite, b: ISite) => compareWithLocale(a.site_store_number, b.site_store_number),
            ...getColumnSortOrder('site_store_number', pageState.sorter),
        },
        {
            key: 'address',
            title: 'Address',
            render: (text, record) => <SiteAddress address={record.site_address} city={record.site_city} state={record.site_state} />,
        },
        {
            key: 'site_utility',
            ...(!sidebarEnabled
                ? { ...buildSiteUtilityTableFilter(pageState, utilities) }
                : {
                      ...omit(buildSiteUtilityTableFilter(pageState, utilities), ['filters', 'filteredValue', 'filterSearch']),
                      filtered: false,
                  }),
        },
        {
            key: 'program_id',
            ...(!sidebarEnabled
                ? {
                      ...buildProgramTableFilter(pageState, programsToDisplay),
                  }
                : {
                      ...omit(buildProgramTableFilter(pageState, programsToDisplay), ['filters', 'filteredValue', 'filterSearch']),
                      filtered: false,
                  }),
        },
        {
            key: 'lmp_market',
            title: 'Market, Load Zone',
            dataIndex: 'lmp_market',
            // Hide the legacy filter if the sidebar is enabled
            ...(!sidebarEnabled
                ? {
                      filterMultiple: false,
                      filters: marketZoneFilterOptions,
                      ...getColumnFilteredValue('lmp_market', pageState.filter),
                  }
                : { filtered: false }),
            sorter: (a: ISite, b: ISite) => compareWithLocale(a.lmp_market, b.lmp_market),
            ...getColumnSortOrder('lmp_market', pageState.sorter),
            render: (text, record) => <SiteMarketAndLoadZone site={record} />,
        },
        {
            key: 'sans',
            title: 'Enrollment ID',
            dataIndex: 'sans',
            render: (text, site) => (
                <SiteSANs market={site.lmp_market} utility={site.site_utility} sans={site.sans} searchString={pageState?.search} />
            ),
        },
        ...(isAdminRole ? [...buildSiteStatusTableFilter(pageState, sidebarEnabled)] : []),
        ...(isAdminRole || isControlProviderRole
            ? [
                  {
                      key: 'interval_data',
                      title: 'Interval Data connection',
                      width: 120,
                      dataIndex: 'interval_data',
                      ...(!sidebarEnabled
                          ? {
                                filterMultiple: false,
                                filters: SITE_INTERVAL_DATA_FILTER_VALUES,
                                ...getColumnFilteredValue('interval_data', pageState.filter),
                            }
                          : { filtered: false }),

                      render: (text: string, record: ISite) => {
                          const activeSan = getActiveSiteSan(record);
                          return <IntervalDataTag sanInfo={activeSan?.san_info} />;
                      },
                  },
              ]
            : []),
        ...(isAdminRole
            ? [
                  {
                      key: 'exist_salesforce_meters',
                      title: 'Meters',
                      width: 90,
                      dataIndex: 'exist_salesforce_meters',
                      ...(!sidebarEnabled
                          ? {
                                filterMultiple: false,
                                ...getColumnFilteredValue('exist_salesforce_meters', pageState.filter),
                                filters: [
                                    { text: 'Linked Meters', value: 'true' },
                                    { text: 'Not linked Meters', value: 'false' },
                                ],
                            }
                          : { filtered: false }),

                      render: (text: string, record: ISite) => record?.meters?.length,
                  },
              ]
            : []),
        ...(isAdminRole
            ? [
                  {
                      key: 'exist_salesforce_meter_enrollments',
                      title: 'Meter Enrollments',
                      width: 120,
                      ...(!sidebarEnabled
                          ? {
                                filterMultiple: false,
                                filters: [
                                    { text: 'Found at Salesforce', value: 'true' },
                                    { text: 'Not found at Salesforce', value: 'false' },
                                ],
                                ...getColumnFilteredValue('exist_salesforce_meter_enrollments', pageState.filter),
                            }
                          : { filtered: false }),
                      dataIndex: 'exist_salesforce_meter_enrollments',
                      render: (text: string, record: ISite) =>
                          record?.meters.reduce((sum, meter) => (meter?.meter_enrollments?.length ? sum + meter.meter_enrollments.length : sum), 0),
                  },
              ]
            : []),
        {
            key: 'action',
            sorter: false,
            render: (text: string, record: ISite) => <ActionList actions={actions} item={record} />,
        },
    ];

    // Trigger re-render to apply column settings, including sorting
    useEffect(() => {
        if (siteColumns) {
            setColumns(siteColumns);
        }
    }, [safeStringify(siteColumns)]);

    useEffect(() => {
        const sitesFilter = {
            ...buildSiteUtilityTableFilter(pageState, utilities),
        };
        const programsFilter = {
            ...buildProgramTableFilter(pageState, companyId ? filterPrograms : programs),
        };

        setFilters({
            rootPage: 'sites',
            props: [
                {
                    ...(ability.can('read', 'ControlProvider')
                        ? {
                              title: 'Control Provider',
                              key: 'control_provider_id',
                              filterMultiple: true,
                              items: buildControlProviderFilter(controlProviderCompanies, {
                                  includeWithoutControlProvider: true,
                              }),
                              ...getColumnFilteredValue('control_provider_id', pageState.filter),
                          }
                        : {}),
                },
                {
                    ...(ability.can('read', 'UtilityCustomer')
                        ? {
                              title: 'Utility Customer',
                              filterMultiple: false,
                              key: 'utility_customer_id',
                              items: buildUtilityCustomerFilter(utilityCustomerCompanies),
                              ...getColumnFilteredValue('utility_customer_id', pageState.filter),
                          }
                        : {}),
                },
                {
                    title: String(sitesFilter.title),
                    items: sitesFilter.filters,
                    key: String(sitesFilter.dataIndex),
                },
                {
                    title: programsFilter.title,
                    items: programsFilter.filters,
                    key: String(programsFilter.dataIndex),
                },
                {
                    title: 'Market, Load Zone',
                    items: marketZoneFilterOptions,
                    key: 'lmp_market',
                    filterMultiple: false,
                    ...getColumnFilteredValue('lmp_market', pageState.filter),
                },
                {
                    ...(auth.user?.isAdminRoleType()
                        ? {
                              title: 'Status',
                              key: 'status',
                              items: SITE_STATUS_FILTER_VALUES,
                              filteredValue: getStatusFilterValue(pageState),
                          }
                        : {}),
                },
            ],
        });

        setOptions({
            rootPage: 'sites',
            props: [
                {
                    ...(isAdminRole
                        ? {
                              title: 'Interval Data connection',
                              key: 'interval_data',
                              filterMultiple: false,
                              items: SITE_INTERVAL_DATA_FILTER_VALUES,
                              ...getColumnFilteredValue('interval_data', pageState.filter),
                          }
                        : {}),
                },
                {
                    ...(isAdminRole
                        ? {
                              title: 'Meters',
                              key: 'exist_salesforce_meters',
                              filterMultiple: false,
                              ...getColumnFilteredValue('exist_salesforce_meters', pageState.filter),
                              items: [
                                  { text: 'Linked Meters', value: 'true' },
                                  { text: 'Not linked Meters', value: 'false' },
                              ],
                          }
                        : {}),
                },
                {
                    ...(isAdminRole
                        ? {
                              title: 'Meter Enrollments',
                              filterMultiple: false,
                              key: 'exist_salesforce_meter_enrollments',
                              items: [
                                  { text: 'Found at Salesforce', value: 'true' },
                                  { text: 'Not found at Salesforce', value: 'false' },
                              ],
                              ...getColumnFilteredValue('exist_salesforce_meter_enrollments', pageState.filter),
                          }
                        : {}),
                },
                {
                    ...(ability.can('close-open', 'Site')
                        ? {
                              subHeader: 'Show Closed Sites',
                              title: 'Closed Sites',
                              key: 'isClosed',
                              filterType: SidebarFilterType.TOGGLE,
                              ...getColumnFilteredValue('isClosed', pageState.filter),
                          }
                        : {}),
                },
            ],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setOptions, setFilters, companies, utilities, programs, programsByCompany]);

    const onSelectRow = (selectedRows: Key[]) => {
        handleSelectedItems(selectedRows as number[], sites);
    };

    const batchSiteActionsMenu = (
        <Menu>
            <MenuItem key="unenroll-all-program" onClick={() => setSiteAction(SPECIFIC_SITE_ACTION.BATCH_UNENROLL_PROGRAMS)}>
                Remove All Programs from Selection
            </MenuItem>
        </Menu>
    );

    const handleColumnResize = useCallback(
        (index: number, deltaWidth: number) => {
            setColumns(prevColumns =>
                prevColumns.map((col, colIndex) => {
                    if (colIndex === index) {
                        const currentWidth = Number(col.width ?? 0);
                        const newWidth = currentWidth + deltaWidth;

                        return {
                            ...col,
                            width: currentWidth < 50 ? 50 : newWidth,
                        };
                    }
                    return col;
                })
            );
        },
        [setColumns]
    );

    const mergedColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column: any) => ({
            width: column.width,
            columnIndex: index,
            style: { cursor: !!column.sorter ? 'pointer' : undefined },
            title:
                typeof column.title === 'function'
                    ? String(column.title()) // Call the function and convert its result to string
                    : column.title,
            onColumnResize: handleColumnResize,
        }),
    }));

    return (
        <>
            <div style={{ marginTop: !sidebarEnabled ? 0 : 30 }}>
                <PageHeader
                    pageTitle={`Sites ${!sidebarEnabled ? `(${meta?.total})` : ''}`}
                    actions={[
                        selectedSiteIds.length > 0 && (
                            <React.Fragment key="selection-site-actions">
                                {ability.can('attach', 'ControlProvider') && (
                                    <Button
                                        key="update-fields-site-batch"
                                        size="large"
                                        onClick={() => setSiteAction(SPECIFIC_SITE_ACTION.UPDATE_SPECIFIC_FIELDS)}
                                    >
                                        Update ({selectedSiteIds.length})
                                    </Button>
                                )}

                                {(ability.can('enroll', 'Program') ||
                                    ability.can('enroll', 'PriceResponse') ||
                                    ability.can('enroll', 'CleanResponse')) && (
                                    <Dropdown.Button
                                        key="enroll-sites-to-program-batch"
                                        size="large"
                                        overlay={batchSiteActionsMenu}
                                        onClick={() => setSiteAction(SPECIFIC_SITE_ACTION.ATTACH_PROGRAMS)}
                                    >
                                        Attach programs ({selectedSiteIds.length})
                                    </Dropdown.Button>
                                )}
                                {isAllowedToMerge && (
                                    <Button size="large" key="merge-sites" onClick={() => setSiteAction(SPECIFIC_SITE_ACTION.MERGE)}>
                                        Merge ({selectedSiteIds.length})
                                    </Button>
                                )}
                                <Button size="large" key="clear-selection-action" onClick={() => handleSelectedItems([], sites)}>
                                    Clear Selected({selectedSiteIds.length})
                                </Button>
                            </React.Fragment>
                        ),
                        <SearchInput key="site-search" onSearch={handleSearch} defaultValue={pageState?.search} />,
                        ability.can('update', 'Site') && (
                            <Button key="import-csv" onClick={showImportModal} icon={<UploadOutlined />} size="large" data-cy="import-action">
                                Import
                            </Button>
                        ),
                        <SiteEnrollmentExportButton filter={toUsefulParams({ ...pageState, companyId: companyId })} key="download-csv" />,
                        // Hide the open/close sites button if the sidebar is enabled
                        !sidebarEnabled && ability.can('close-open', 'Site') && (
                            <Button key="open-close-sites" size="large" onClick={toggleClosedSites} disabled={isLoading}>
                                {isLoading ? 'Loading...' : isClosed ? 'Show Open Sites' : 'Show Closed Sites'}
                            </Button>
                        ),
                        ability.can('create', 'AsyncJob') && (
                            <SyncSalesforceButton jobName={JobName.SYNC_SALESFORCE_METERS_WITH_ENROLLMENTS} key="sync-salesforce" />
                        ),
                        ability.can('update', 'Site') && (
                            <Button
                                key="new-site"
                                type="primary"
                                onClick={showNewSiteModal}
                                icon={<PlusCircleOutlined />}
                                size="large"
                                data-cy="create-site"
                            >
                                New Site
                            </Button>
                        ),
                    ]}
                    extra={
                        hiddenColumnsCount > 0 && (
                            <div className="hidden-columns-sites-table">
                                <span>{hiddenColumnsCount} Columns Hidden</span>
                            </div>
                        )
                    }
                />
            </div>
            <WithEmptyDataTable condition={isLoading}>
                <Table
                    size="small"
                    rowKey="site_id"
                    className="sites-table"
                    sticky
                    rowClassName={record => (record.site_clean_response_enrolled ? 'enrolled-site-row' : '')}
                    columns={mergedColumns}
                    components={{
                        header: {
                            cell: ResizableColumnTitle,
                        },
                    }}
                    dataSource={sites}
                    loading={isFetching}
                    onChange={onTableChange}
                    rowSelection={
                        ability.can('update', 'Site')
                            ? {
                                  selectedRowKeys: selectedSiteIds,
                                  onChange: onSelectRow,
                                  preserveSelectedRowKeys: true,
                                  getCheckboxProps: (site: ISite) => {
                                      return {
                                          // Disable all updates for closed sites
                                          disabled: !!site.closed_at,
                                      };
                                  },
                              }
                            : undefined
                    }
                    pagination={getTablePaginationParams(meta)}
                    expandable={{
                        expandedRowRender: site => <ExtraSiteInformation site={site} />,
                        rowExpandable: site => isAdminRole! && (!!site.meters.length || !!site.sans.length),
                    }}
                />
            </WithEmptyDataTable>

            {siteAction === SPECIFIC_SITE_ACTION.UPDATE_SPECIFIC_FIELDS && (
                <UpdateSitesBatchModal companies={companies} sitesId={selectedSiteIds} onCancel={closeSiteBatchModal} selectedSites={selectedSites} />
            )}

            {site && siteAction === PAGE_ACTION.NEW_EDIT && <SiteFormModal site={site} companies={companies} onClose={onSiteFormModalClose} />}

            {site && siteAction === SPECIFIC_SITE_ACTION.CLOSE_SITE && <CloseSiteModal site={site as ISite} onCancel={onSiteFormModalClose} />}

            {site && siteAction === SPECIFIC_SITE_ACTION.OPEN_SITE && <OpenSiteModal site={site as ISite} onCancel={onSiteFormModalClose} />}

            {site && siteAction === PAGE_ACTION.AUDIT_TRAIL && (
                <AuditTrailModal onClose={onSiteFormModalClose} entityId={site.site_id!} entityType={AuditEntityType.SITE} />
            )}

            {siteAction === SPECIFIC_SITE_ACTION.IMPORT && <ImportSitesModal onCancel={onImportModalClose} />}

            {site && siteAction === SPECIFIC_SITE_ACTION.ADD_VEN && (
                <CustomerVenFormModal
                    ven={prepareVen(site as ISite)}
                    onClose={onSiteFormModalClose}
                    isEditMode={Boolean(prepareVen(site as ISite).ven_id)}
                />
            )}

            {site && siteAction === PAGE_ACTION.DELETE && (
                <DeleteSiteModal site={site as ISite} onSubmit={deleteSiteAction} isLoading={isDeleteLoading} onClose={onSiteFormModalClose} />
            )}

            {siteAction === SPECIFIC_SITE_ACTION.MERGE && <MergeSitesModal selectedSites={selectedSites} onClose={onMergeSitesModalClose} />}

            {site && siteAction === PAGE_ACTION.ENROLL_PROGRAM && (
                <EnrollProgramModal site={site as ISite} programs={programs} onCancel={onSiteFormModalClose} />
            )}

            {siteAction === SPECIFIC_SITE_ACTION.BATCH_UNENROLL_PROGRAMS && (
                <BatchUnenrollProgramsModal onCancel={closeBatchUnenrollModal} sites={selectedSites} />
            )}

            {siteAction === SPECIFIC_SITE_ACTION.ATTACH_PROGRAMS && (
                <AttachProgramBatchModal sitesId={selectedSiteIds} onCancel={closeSiteBatchModal} selectedSites={selectedSites} />
            )}
        </>
    );
}
