import { Market } from 'src/domain/market-prices/interface';

export class PeakLoadForecastMarket {
    readonly name: Market;
    readonly loadZone: string;
    readonly timezone: string;
    readonly isSupported: boolean;

    constructor(market: Market, loadZone: string) {
        this.name = market;
        this.loadZone = loadZone;

        /** in the future, when more markets are supported - we may have different timezones here */
        this.timezone = this._getTimezone();
        this.isSupported = this._isSupported();
    }

    private _isSupported() {
        const supportedMarkets = [Market.ISONE, Market.NYISO, Market.ERCOT, Market.PJM];
        return supportedMarkets.includes(this.name?.toLowerCase() as Market);
    }

    private _getTimezone(): string {
        switch (this.name.toLowerCase()) {
            case Market.ERCOT:
                return 'America/Chicago'; // CST/CDT
            case Market.CAISO:
                return 'America/Los_Angeles'; // PST/PDT
            case Market.ISONE:
            case Market.NYISO:
            case Market.PJM:
            default:
                return 'America/New_York'; // EST/EDT
        }
    }
}
